import { useState } from "react";
import { Button, CircularProgress, InputLabel, Switch, TextField } from "@mui/material";
import DialogC from "../../../components/Dialog";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useMutation } from 'react-query'
import { savePropertyFacility } from "../../../services/api/PropertyFacility";

export default function Add({ open, setOpen, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataFacility, setDataFacility] = useState({})
  let { reset, handleSubmit, register, formState: { errors } }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  const mutation = useMutation(savePropertyFacility, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('add', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('add', false, { }, true) }
    }
  })

  const handleAdd = (params) => { 
    console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate(params)
  }
  
  const handleClose = () => { reset(); setDataFacility({}); setOpen(false) }

  const handleOnChangeData = (event) => { 
    setDataFacility({...dataFacility, [event.target.name]: event.target.value}) 
  }

  const handleChangeActive = (event) => {
    setDataFacility({...dataFacility, active: !dataFacility.active}) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
      </> : <CircularProgress /> }
  </>)

  return (
    <DialogC open={open} title={`Nueva instalación`} actions={<Actions />}>
      <>
        <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataFacility.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='description' style={{ fontSize: 14 }}>Descripción</InputLabel>
        <TextField id='description' fullWidth margin="dense" type="text" name='description'
          multiline
          maxRows={4}
          value={dataFacility.description || ""}
          {...register(`description`)} 
          error={errors.description ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.description?.message} />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataFacility.icon || "/images/picture_empty.png"} style={{ width: "50%" }}/>
          </div>
          <div>
          <InputLabel htmlFor='icon' style={{ fontSize: 14 }}>Icono</InputLabel>
          <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
            value={dataFacility.icon || ""}
            {...register(`icon`)} 
            error={errors.icon ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.icon?.message} />
          </div>
        </div>
        <br />
        <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activo?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={handleChangeActive}
          checked={dataFacility.active || false} 
          {...register(`active`)} 
        />
      </>
    </DialogC>
  )
}