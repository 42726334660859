import styles from './styles.module.css'

export default function HorizontalData({children, actionsAlign, actions}) {
  
  return (
    <div className={styles.card_horizontal}>
      <div className={styles.card_horizontal_data}>
        {children}
        <div style={{ textAlign: actionsAlign}}>{actions}</div>
      </div>
    </div>
  )
}