import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaFacility } from "../services/SchemaValidation"
import { Button, IconButton, InputLabel, TextField, Typography  } from "@mui/material";
import { ImageSearch as ImageSearchIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";

export default function AddEditForm({ isLoading, editPosition, mode, setMode, handleOpenMedia, updateArray, appendArray, setValues }) {
  let schemaFacility = { icon: "", description: "" }
  const [instanceFacility, setInstanceFacility] = useState(schemaFacility)
  //Facility
  let { reset: resetOne, handleSubmit: handleSubmitOne, register: registerOne, formState: { errors: errorsOne }, setValue: setValueOne} = useForm({ resolver: yupResolver(ValidationSchemaFacility) })

  useEffect(() => {
    if(setValues !==  null) {
      let instanceTemp = {...instanceFacility}
      setValues.forEach(data => {
        if(data.reference === "reset") {
          resetOne(schemaFacility);
        } else {
          setValueOne(data.reference, data.value)
          instanceTemp[data.reference] = data.value
        }
      })
      setInstanceFacility(instanceTemp)
    }
  }, [setValues]) 
  
  const handleChangeData = (event) => { 
    setInstanceFacility({...instanceFacility, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditFacility = () => { setInstanceFacility({}); resetOne(schemaFacility); setMode(true) }

  const handleSaveEdit = () => {
    updateArray(editPosition, instanceFacility)
    setInstanceFacility({}); resetOne(schemaFacility); setMode(true)
  }

  const onAddFacility = (params) => {
    appendArray(params)
    resetOne(schemaFacility)
    setInstanceFacility(schemaFacility)
  }

  return (<>
    <Typography variant="inherit">{ mode ? "Agregar" : "Editar" } extra logo</Typography><br />
    { (instanceFacility.icon !== undefined && instanceFacility.icon !== null && instanceFacility.icon !== "") && <div style={{ textAlign: "center" }}>
      <img alt="preview-url" src={instanceFacility.icon} style={{ width: "100%", maxWidth: "250px" }}/>
    </div> }
    <InputLabel htmlFor='icon' required style={{ fontSize: 14 }}>Ícono</InputLabel>
    <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
      value={instanceFacility.icon || ""}
      {...registerOne(`icon`)} 
      error={errorsOne.icon ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.icon?.message} />
    <IconButton onClick={() => handleOpenMedia("addlogo")}> <ImageSearchIcon color="info" /> </IconButton><br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <TextField id='description' fullWidth margin="dense" type="text" name='description'
      value={instanceFacility.description || ""}
      {...registerOne(`description`)} 
      error={errorsOne.description ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.description?.message} />
    {!isLoading ? <div style={{ textAlign: "right" }}>
      { (!mode) ? <>
        <Button onClick={handleCancelEditFacility} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
        <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
      </> :  <Button onClick={handleSubmitOne(onAddFacility)} variant="contained" color="primary"> Agregar </Button> }
    </div> : <></> }      
  </>)
}