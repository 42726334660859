import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaBasePrices } from "../services/SchemaValidation"
import { useMutation } from "react-query";
import { editPropertyBasePrice } from "../../../services/api/Property";
import { CircularProgress, IconButton } from "@mui/material";
import FullwidthDialog from '../../../components/Dialog/FullwidthDialog'
import { Save as SaveIcon } from '@mui/icons-material';
import BasePriceF from "./BasePriceF";

export default function BasePrice({ open, setOpen, property, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({})
  //Property BasePrice
  let { handleSubmit, register, formState: { errors }, control, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaBasePrices),
    defaultValues: { 'base_price': [] } 
  })
  const { fields: fieldsBP, append: appendBP, remove: removeBP, update: updateBP } = useFieldArray({ 
    name: 'base_price', control 
  })

  useEffect(() => { 
    if(open) { 
      setData(property)
      if (property.base_price !== undefined && property.BasePrbase_priceice !== null) {
        setValue("base_price", property.base_price)
      }
    } 
  }, [open])

  const mutation = useMutation(editPropertyBasePrice, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction('basePrice', false, { message: 'Error al editar los datos'}, false)
      } else { 
        handleClose()
        reportAction('basePrice', false, { }, true) 
      }
    }
  })

  const onSubmit = (params) => {
    console.log("Params:", params)
    setIsLoading(true)
    mutation.mutate({id: property._id, params: params.base_price})
  }

  const handleClose = () => { setValue('texts', []); setData({}); setOpen(false) }

  return (<>
    <FullwidthDialog open={open} changeOpen={handleClose} title="Editar precios base" actions={<>
      { !isLoading ? <>
      <IconButton autoFocus color="inherit" onClick={handleSubmit(onSubmit)} aria-label="save"> <SaveIcon /> </IconButton> </> : <CircularProgress /> }
    </>}>
      <BasePriceF isLoading={isLoading} fields={fieldsBP} updateArray={updateBP} appendArray={appendBP} removeArray={removeBP} />
    </FullwidthDialog>
  </>)
}