
export const Select = ({ label = '', value, options = [], onChange }) => {
  return (
    <label>
      {label && <span>{label}：</span>}
      <select value={value} onChange={onChange}>
        {options.map((item, key) => {
          const optionProps = {};
          if (value === item) {
            optionProps.value = item;
          }
          return (
            <option key={key} {...optionProps}>
              {item}
            </option>
          );
        })}
      </select>
    </label>
  );
};