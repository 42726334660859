import { /*useEffect,*/ useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaWebText } from "../services/SchemaValidation"
import { Button, InputLabel, TextField, IconButton, Typography, Grid } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";
import styles from '../styles.module.css'
import SimpleDataCard from "../../../components/Card/SimpleDataCard";

export default function Texts({ isLoading, fields, updateArray, appendArray, removeArray }) {
  let schemaWebText = { text: "", identifier: "" }
  const [editPositionWebText, setEditPositionWebText] = useState(null)
  const [modeWebText, setModeWebText] = useState(true)
  const [instanceWebText, setInstanceWebText] = useState(schemaWebText)
  //WebText
  let { reset: resetOneText, handleSubmit: handleSubmitOneText, register: registerOneText, formState: { errors: errorsOneText }, setValue: setValueOneText} = useForm({ resolver: yupResolver(ValidationSchemaWebText) })

  const handleChangeData = (event) => { 
    setInstanceWebText({...instanceWebText, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditWebText = () => { setInstanceWebText({}); resetOneText(schemaWebText); setModeWebText(true) }

  const handleSaveEdit = () => {
    updateArray(editPositionWebText, instanceWebText)
    setInstanceWebText({}); resetOneText(schemaWebText); setModeWebText(true)
  }

  const onAddWebText = (params) => {
    appendArray(params)
    resetOneText(schemaWebText)
    setInstanceWebText(schemaWebText)
  }

  const handleRemoveWebText = (position) => { removeArray(position) }

  const handleEditWebText = (position) => { 
    setModeWebText(false)
    setValueOneText("text", fields[position].text)
    setValueOneText("identifier", fields[position].identifier)
    setInstanceWebText({ text: fields[position].text, identifier: fields[position].identifier })
    setEditPositionWebText(position)
  }

  const Actions = ({ index }) => (<>
    <IconButton aria-label="edit" onClick={ () => handleEditWebText(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveWebText(index) }> <DeleteIcon color='error'/> </IconButton>
  </>)

  return (<>
  <div className={styles.imagelogos_container}>
    <div>
      <h4>Textos</h4>
      { (fields.length > 0) ? (
      fields.map((item, i) => (
        <Grid item xs={12}>
          <SimpleDataCard key={i} title={item.text} subtitle={`id: ${item.identifier}`} actions={<Actions index={i}/>}/><br />
        </Grid>
      )) ) : <Grid item xs={12}> No hay textos agregados </Grid> }
    </div>
    <div>
      <Typography variant="inherit">{ modeWebText ? "Agregar" : "Editar" } texto</Typography><br />
      <InputLabel htmlFor='text' required style={{ fontSize: 14 }}>Texto</InputLabel>
      <TextField id='text' fullWidth margin="dense" type="text" name='text' multiline
        value={instanceWebText.text || ""}
        {...registerOneText(`text`)} 
        error={errorsOneText.text ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errorsOneText.text?.message} /><br />
      <InputLabel htmlFor='identifier' required style={{ fontSize: 14 }}>Identificador</InputLabel>
      <TextField id='identifier' fullWidth margin="dense" type="text" name='identifier'
        value={instanceWebText.identifier || ""}
        {...registerOneText(`identifier`)} 
        error={errorsOneText.identifier ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errorsOneText.identifier?.message} />
      {!isLoading ? <div style={{ textAlign: "right" }}>
        { (!modeWebText) ? <>
          <Button onClick={handleCancelEditWebText} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
          <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
        </> :  <Button onClick={handleSubmitOneText(onAddWebText)} variant="contained" color="primary"> Agregar </Button> }
      </div> : <></> }
    </div>
  </div>
  </>)
}