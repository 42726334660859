import { useContext, useState } from "react";
import { AppBar, CssBaseline, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Toolbar, Typography /*, useTheme*/ } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { /*Link,*/ Link, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext'
import MenuIcon from '@mui/icons-material/Menu'
import { Box } from "@mui/system";
import { MenuItems } from "./Menu";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" }, 
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  optionDrawer: {
    textDecoration: 'none',
    color: '#000000'
  },
}))

export default function AdminLayout() {
  //const theme = useTheme()
  const classes = useStyles()
  const { pathname } = useLocation();
  const { logOut, titlePage, isAuth } = useContext(AuthContext)
  const [open, setOpen] = useState(false)

  const handleToogleDrawer = () => {
    setOpen(!open)
  }

  //const container = window !== undefined ? () => window().document.body : undefined;
  
  const menu = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={handleToogleDrawer}
      onKeyDown={handleToogleDrawer}
    >
      <List>
        {MenuItems.map((item, index) => (
          <Link to={item.to} key={`${index}-${item.text}`} className={classes.optionDrawer}>
            <ListItem button>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={logOut}>
          <ListItemText primary='Cerrar Sesión' />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      { (isAuth && pathname !== "/admin/mapa-interactivo-tb") && <>
      <AppBar position="fixed" /*className={classes.appBar}*/>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleToogleDrawer}
            /*className={classes.menuButton}*/
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap> {!titlePage ? "Admininstración Inmuebles Gicsa" : titlePage} </Typography>
        </Toolbar>
      </AppBar>
      <nav /*className={classes.drawer}*/>
        <SwipeableDrawer
          open={open}
          onClose={handleToogleDrawer}
          onOpen={handleToogleDrawer}
        >
          {menu('left')}
        </SwipeableDrawer>
      </nav>
      </> }
      <main className={classes.content} style={{ width: "100%" }}>
        { (isAuth && pathname !== "/admin/mapa-interactivo-tb") && <div className={classes.toolbar} /> }
        <Outlet />
      </main>
    </div>
  )
}