import { useEffect, useState } from 'react'
import { Button, CircularProgress, IconButton } from "@mui/material";
import { ImageSearch as ImageSearchIcon } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import FullwidthDialog from "../../../components/Dialog/FullwidthDialog";
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'
import ace from 'brace'
import 'brace/mode/json';
import 'brace/theme/github';
import MediaModal from '../../Media/MediaModal'
import styles from '../styles.module.css'
import { useMutation } from "react-query";
import { editInteractiveMap } from "../../../services/api/Property";

let dataJSON = {
	"mapwidth": "1000",
	"mapheight": "500",
	"defaultstyle": "light",
	"developer": true, 
	"styles": [
		{
			"class": "light",
			"hover": { "fill": "#f4f4f4" },
			"active": { "fill": "#fff" }
		},
		{
			"class": "dark",
			"hover": { "fill": "#666" },
			"active": { "fill": "#555" }
		}
	],
	"levels": [
		{
			"id": "piso",
			"title": "Piso",
			"map": "/images/ejemplo_9.svg",
			"locations": [
				{
					"id": "disponible",
					"title": "Disponible"
				},
				{
					"id": "ocupado",
					"title": "Ocupado"
				}
			]
		}
	]
}

export default function InteractiveMap({ open, setOpen, floor, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
	const [editorData, setEditorData] = useState("")
	const [random, setRandom] = useState(0)
  const [openMediaModal, setOpenMediaModal] = useState(false)

	useEffect(() => {
		if (open) {
      let data = dataJSON
      if(floor.interactive_map_data !== undefined) {
        if(floor.interactive_map_data.styles !== undefined) data.styles = floor.interactive_map_data.styles
        if(floor.interactive_map_data.levels !== undefined) data.levels = floor.interactive_map_data.levels
      }
      console.log(data)
			setEditorData(data)
      localStorage.setItem('inmaptb', JSON.stringify(data));
      setRandom(random + 1);
		}
	}, [open])

  const mutation = useMutation(editInteractiveMap, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction('interactive_map', false, { message: 'Error al editar los datos'}, false)
      } else { 
        handleClose()
        reportAction('interactive_map', false, { }, true) 
      }
    }
  })

  const onSubmit = () => {
    let dataTemp = {}
    dataTemp.styles = editorData.styles
    dataTemp.levels = editorData.levels
    // let data = editorData.levels[0]
    dataTemp.levels[0].id = floor._id
    dataTemp.levels[0].title = floor.name
    console.log(dataTemp)
    setIsLoading(true)
    mutation.mutate({ id: floor._id, params: dataTemp })
  }

	const handleClose = () => { localStorage.removeItem('inmaptb'); setEditorData(""); setOpen(false) }

	const handleChange = async (data) => {
		setEditorData(data)
	}

	const handleRefresData = () => {
		localStorage.setItem('inmaptb', JSON.stringify(editorData));
		setRandom(random + 1);
	}

  const handleOpenMedia = () => { setOpenMediaModal(true) }

  const handleMediaSelected = (imgUrl) => {
    let dataTemp = {...editorData}
    dataTemp.levels[0].map = imgUrl
    setEditorData(dataTemp)
    setTimeout(() => {
      handleRefresData()
    }, 500)
  }

	return (<>
		<FullwidthDialog open={open} changeOpen={handleClose} title={`Actualizar mapa interactivo - ${floor.property_name}`} actions={<>
			{!isLoading ? <>
				<IconButton autoFocus color="inherit" onClick={ () => onSubmit() } aria-label="save">
					<SaveIcon />
				</IconButton>
			</> : <CircularProgress />}
		</>}>
			<div className={styles.container_interactive_map}>
				<div>
					<iframe key={random} src='/admin/mapa-interactivo-tb' style={{ width: "100%", height: "80vh" }} />
				</div>
				<div>
          <IconButton onClick={() => handleOpenMedia("image")}> <ImageSearchIcon color="info" /> </IconButton><br />
          <Button onClick={handleRefresData} variant="contained" color="primary">Recargar</Button>
					<input type="hidden" id="input_editor_data" value={JSON.stringify(editorData)} />
					{<div className={styles.jsonEditor}>
						<Editor key={random}
							value={editorData}
							onChange={handleChange}
							ace={ace}
							mode="code"
							theme="ace/theme/github"
						/>
          </div>}
				</div>
			</div>
		</FullwidthDialog>
    <MediaModal open={openMediaModal} setOpen={setOpenMediaModal} mediaSelected={handleMediaSelected} path={`propiedadesMapasInteractivos/${floor._id}`} />
	</>)
}