import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaSocialLink } from "../services/SchemaValidation"
import { Button, IconButton, InputLabel, TextField, Typography  } from "@mui/material";
import { ImageSearch as ImageSearchIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";

export default function AddEditForm({ isLoading, editPosition, mode, setMode, handleOpenMedia, updateArray, appendArray, setValues }) {
  let schemaSocialLink = { icon: "", link: "" }
  const [instanceSocialLink, setInstanceSocialLink] = useState(schemaSocialLink)
  //SocialLink
  let { reset: resetOne, handleSubmit: handleSubmitOne, register: registerOne, formState: { errors: errorsOne }, setValue: setValueOne} = useForm({ resolver: yupResolver(ValidationSchemaSocialLink) })

  useEffect(() => {
    if(setValues !==  null) {
      let instanceTemp = {...instanceSocialLink}
      setValues.forEach(data => {
        if(data.reference === "reset") {
          resetOne(schemaSocialLink);
        } else {
          setValueOne(data.reference, data.value)
          instanceTemp[data.reference] = data.value
        }
      })
      setInstanceSocialLink(instanceTemp)
    }
  }, [setValues]) 
  
  const handleChangeData = (event) => { 
    setInstanceSocialLink({...instanceSocialLink, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditSocialLink = () => { setInstanceSocialLink({}); resetOne(schemaSocialLink); setMode(true) }

  const handleSaveEdit = () => {
    updateArray(editPosition, instanceSocialLink)
    setInstanceSocialLink({}); resetOne(schemaSocialLink); setMode(true)
  }

  const onAddSocialLink = (params) => {
    appendArray(params)
    resetOne(schemaSocialLink)
    setInstanceSocialLink(schemaSocialLink)
  }

  return (<>
    <Typography variant="inherit">{ mode ? "Agregar" : "Editar" } link social</Typography><br />
    { (instanceSocialLink.icon !== undefined && instanceSocialLink.icon !== null && instanceSocialLink.icon !== "") && <div style={{ textAlign: "center" }}>
      <img alt="preview-url" src={instanceSocialLink.icon} style={{ width: "100%", maxWidth: "250px" }}/>
    </div> }
    <InputLabel htmlFor='icon' required style={{ fontSize: 14 }}>Ícono</InputLabel>
    <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
      value={instanceSocialLink.icon || ""}
      {...registerOne(`icon`)} 
      error={errorsOne.icon ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.icon?.message} />
    <IconButton onClick={() => handleOpenMedia("addlogo")}> <ImageSearchIcon color="info" /> </IconButton><br />
    <InputLabel htmlFor='link' required style={{ fontSize: 14 }}>Link</InputLabel>
    <TextField id='link' fullWidth margin="dense" type="text" name='link'
      value={instanceSocialLink.link || ""}
      {...registerOne(`link`)} 
      error={errorsOne.link ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.link?.message} />
    {!isLoading ? <div style={{ textAlign: "right" }}>
      { (!mode) ? <>
        <Button onClick={handleCancelEditSocialLink} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
        <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
      </> :  <Button onClick={handleSubmitOne(onAddSocialLink)} variant="contained" color="primary"> Agregar </Button> }
    </div> : <></> }      
  </>)
}