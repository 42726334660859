import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Campo requerido"),
  icon: Yup.string()
    .nullable(),
  description: Yup.string()
    .nullable(),
  active: Yup.boolean()
    .required("Campo requerido"),
})