import * as React from 'react';
import { Box, Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function ImageCard({ index, totalItems, image="", name, description, edit=null, remove=null, changeUpPosition, changeDownPosition }) {

  return (
    <Card sx={{ display: 'flex' }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={image}
        alt={name}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5"> {name} </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div"> {description} </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          { edit !== null && <IconButton aria-label="edit" onClick={edit}> <EditIcon color="info"/> </IconButton> }
          { remove !== null && <IconButton aria-label="delete" onClick={remove}> <DeleteIcon color='error'/> </IconButton> }
          <div>
          {index !== totalItems - 1 && <IconButton aria-label="down-position" onClick={changeDownPosition}> <ArrowDownwardIcon /> </IconButton> }
          {index !== 0 && <IconButton aria-label="up-position" onClick={changeUpPosition}> <ArrowUpwardIcon /> </IconButton>}
          </div>
        </Box>
      </Box> 
    </Card>
  );
}