import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext"
import AmenityCard from "../../components/Card/AmenityCard";
import { useQuery, useQueryClient } from "react-query"
import { getPropertyAmenities } from "../../services/api/PropertyAmenity"
import styles from "./estilos.module.css"
import AddIcon from "@mui/icons-material/Add"
import Add from './complementary/Add'
import Edit from './complementary/Edit'
import Delete from './complementary/Delete'
import { SnackbarGlobal } from "../../components/Snackbar";
import { Button, Fab, Tooltip } from "@mui/material";

export default function PropertyAmenities() {
  const { setTitlePage } = useContext(AuthContext)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [amenitySelected, setAmenitySelected] = useState({})
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [authError, setAuthError] = useState(false);
  const queryClient = useQueryClient()
  const { isLoading, isFetching, error, data } = useQuery(
    'propertyAmenitiesList', 
    getPropertyAmenities,
    { refetchOnWindowFocus: false }
  )

  useEffect(() => { setTitlePage("Amenidades") }, [])

  const handleRefresData = () => { queryClient.invalidateQueries('propertyAmenitiesList') }

  const handleResetActions = (action) => {
    if(action === "add") setOpenAdd(false)
    if(action === "edit") setOpenEdit(false)
    if(action === "delete") setOpenDelete(false)
  }

  const handleWatchAction = (action, cancel, error, success) => {
    if(cancel) return;
    if(error.message) {
      setMessageSnackbar(error.message);
      setAuthError(true);
      return;
    }
    if(success) {
      alert("Acción realizada correctamente")
      handleRefresData()
      handleResetActions(action)
      return;
    }
  }

  if(isLoading || isFetching) return <div>Cargando...</div>

  if(error) return <div className={styles.box_container}> Ha ocurrido un error </div>

  return (<>
    <div className={styles.box_container}>
      {data.data.map((item) => 
        <AmenityCard key={item._id} 
          name={item.name} 
          description={item.description} 
          image={item.icon || "/images/picture_empty.png"} 
          actionEdit={() => { setOpenEdit(true); setAmenitySelected(item) }}
          actionDelete={() => { setOpenDelete(true); setAmenitySelected(item) }}
        />
      )}
    </div>
    <span className={styles.fab_add}>
      <Tooltip title="Agregar"><Fab color="primary" onClick={() => setOpenAdd(true)}><AddIcon /></Fab></Tooltip>
    </span>    
    <Add open={openAdd} setOpen={setOpenAdd} amenity={amenitySelected} reportAction={handleWatchAction}/>
    <Edit open={openEdit} setOpen={setOpenEdit} amenity={amenitySelected} reportAction={handleWatchAction}/>
    <Delete open={openDelete} setOpen={setOpenDelete} amenity={amenitySelected} reportAction={handleWatchAction}/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
  </>)
}