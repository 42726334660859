import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaMobility } from "../services/SchemaValidation"
import { Button, IconButton, InputLabel, TextField, Typography  } from "@mui/material";
import { ImageSearch as ImageSearchIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";

export default function AddEditForm({ isLoading, editPosition, mode, setMode, handleOpenMedia, updateArray, appendArray, setValues }) {
  let schemaMobility = { icon: "", description: "" }
  const [instanceMobility, setInstanceMobility] = useState(schemaMobility)
  //Mobility
  let { reset: resetOne, handleSubmit: handleSubmitOne, register: registerOne, formState: { errors: errorsOne }, setValue: setValueOne} = useForm({ resolver: yupResolver(ValidationSchemaMobility) })

  useEffect(() => {
    if(setValues !==  null) {
      let instanceTemp = {...instanceMobility}
      setValues.forEach(data => {
        if(data.reference === "reset") {
          resetOne(schemaMobility);
        } else {
          setValueOne(data.reference, data.value)
          instanceTemp[data.reference] = data.value
        }
      })
      setInstanceMobility(instanceTemp)
    }
  }, [setValues]) 
  
  const handleChangeData = (event) => { 
    setInstanceMobility({...instanceMobility, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditMobility = () => { setInstanceMobility({}); resetOne(schemaMobility); setMode(true) }

  const handleSaveEdit = () => {
    updateArray(editPosition, instanceMobility)
    setInstanceMobility({}); resetOne(schemaMobility); setMode(true)
  }

  const onAddMobility = (params) => {
    appendArray(params)
    resetOne(schemaMobility)
    setInstanceMobility(schemaMobility)
  }

  return (<>
    <Typography variant="inherit">{ mode ? "Agregar" : "Editar" } extra logo</Typography><br />
    { (instanceMobility.icon !== undefined && instanceMobility.icon !== null && instanceMobility.icon !== "") && <div style={{ textAlign: "center" }}>
      <img alt="preview-url" src={instanceMobility.icon} style={{ width: "100%", maxWidth: "250px" }}/>
    </div> }
    <InputLabel htmlFor='icon' required style={{ fontSize: 14 }}>Ícono</InputLabel>
    <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
      value={instanceMobility.icon || ""}
      {...registerOne(`icon`)} 
      error={errorsOne.icon ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.icon?.message} />
    <IconButton onClick={() => handleOpenMedia("addlogo")}> <ImageSearchIcon color="info" /> </IconButton><br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <TextField id='description' fullWidth margin="dense" type="text" name='description'
      value={instanceMobility.description || ""}
      {...registerOne(`description`)} 
      error={errorsOne.description ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.description?.message} />
    {!isLoading ? <div style={{ textAlign: "right" }}>
      { (!mode) ? <>
        <Button onClick={handleCancelEditMobility} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
        <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
      </> :  <Button onClick={handleSubmitOne(onAddMobility)} variant="contained" color="primary"> Agregar </Button> }
    </div> : <></> }      
  </>)
}