import { useEffect, useState } from 'react'
import { Button, CircularProgress, IconButton } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import FullwidthDialog from "../../../components/Dialog/FullwidthDialog";
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'
import ace from 'brace'
import 'brace/mode/json';
import 'brace/theme/github';
import styles from '../styles.module.css'

let dataJSON = {
	"mapwidth": "500",
	"mapheight": "500",
	"categories": [
		{
			"id": "locations",
			"title": "Locations",
			"color": "#BBDBB4",
			"show": "true"
		},
		{
			"id": "furniture",
			"title": "Furniture",
			"color": "#685044",
			"show": "false"
		}
	],
	"levels": [
		{
			"id": "lower",
			"title": "Lower Level",
			"map": "https://new.mapplic.com/wp-content/uploads/2019/12/lower.jpg",
			"minimap": "maps/apartment/lower-small.jpg",
			"locations": [
				{
					"id": "entrance",
					"title": "Entrance",
					"about": "Main entrance",
					"description": "<p>Welcome to your future apartment!<br>Feel free to look around.</p>",
					"category": "locations",
					"pin": "hidden",
					"x": "0.22",
					"y": "0.59",
					"zoom": "3"
				},
				{
					"id": "coffeetable",
					"title": "Coffee Table",
					"about": "The best place to spend the afternoon",
					"description": "<p>This is an awesome coffee table.<p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean viverra laoreet imperdiet. Praesent viverra, enim at malesuada dignissim, ante purus vestibulum turpis, nec molestie ipsum mauris non risus. In hac habitasse platea dictumst.</p><p>Integer porta dignissim magna, at sollicitudin neque aliquam eget. Pellentesque dapibus, augue eu ornare mattis, est mauris volutpat ligula, ac ornare turpis turpis sit amet elit.</p>",
					"category": "furniture",
					"link": "https://1.envato.market/R5Nv",
					"action": "lightbox",
					"x": "0.2050",
					"y": "0.4660",
					"zoom": "3"
				},
				{
					"id": "coffeemachine",
					"title": "Coffee Machine",
					"about": "For the perfect coffee",
					"description": "<p>Coffee Machine</p>",
					"category": "furniture",
					"action": "tooltip",
					"link": "http://www.codecanyon.net/",
					"x": "0.6792",
					"y": "0.3459",
					"zoom": "3"
				},
				{
					"id": "workingtable",
					"title": "Working Table",
					"about": "This is where the magic happens",
					"description": "It's the perfect home workspace you've always dreamed about.",
					"category": "furniture",
					"x": "0.6285",
					"y": "0.1480",
					"zoom": "3"
				},
				{
					"id": "kitchen",
					"title": "Kitchen",
					"about": "Well equipped kitchen",
					"description": "<p>Welcome to the kitchen.</p>",
					"category": "locations",
					"link": "https://1.envato.market/R5Nv",
					"pin": "hidden",
					"x": "0.665",
					"y": "0.46",
					"zoom": "3"
				},
				{
					"id": "dining",
					"title": "Dining room",
					"about": "Could be a motto",
					"description": "The main living room",
					"category": "locations",
					"pin": "hidden",
					"x": "0.3",
					"y": "0.4",
					"zoom": "3"
				}
			]
		},
		{
			"id": "upper",
			"title": "Upper Level",
			"map": "https://new.mapplic.com/wp-content/uploads/2019/12/upper.jpg",
			"minimap": "maps/apartment/upper-small.jpg",
			"locations": [
				{
					"id": "livingup",
					"title": "Living room",
					"about": "Upper level living",
					"description": "<p>I could spend the whole day here!</p>",
					"category": "locations",
					"x": "0.49",
					"y": "0.36",
					"zoom": "2"
				},
				{
					"id": "kingbed",
					"title": "King bed",
					"about": "Very comfortable king size bed",
					"description": "A king size bed situated in the main bedroom on the first floor.",
					"category": "furniture",
					"x": "0.6564",
					"y": "0.2782",
					"zoom": "3"
				},
				{
					"id": "bathroom",
					"title": "Bathroom",
					"about": "Or anything you want to show in the list",
					"description": "Take a bath",
					"category": "locations",
					"pin": "hidden",
					"x": "0.7843",
					"y": "0.4035",
					"zoom": "3"
				}
			]
		}
	]
}

export default function InteractiveMap({ open, setOpen, towerBuilding, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [editorData, setEditorData] = useState(null)
  const [random, setRandom] = useState(0)

  useEffect(() => {
    if(open) {
      setEditorData(towerBuilding.interactive_map_data ? towerBuilding.interactive_map_data : dataJSON)
    }
  }, [open])

  const handleClose = () => { setOpen(false) }

  const handleChange = async (data) => {
    console.log("Editor Change", data)
    setEditorData(data)
  }

  const handleRefresData = () => {
	  localStorage.setItem('inmaptb', JSON.stringify(editorData));
    setRandom(random + 1);
  }

  return (
    <FullwidthDialog open={open} changeOpen={handleClose} title="Actualizar mapa interactivo" actions={<>
      { !isLoading ? <>
      <IconButton autoFocus color="inherit" onClick={handleClose/*handleSubmit(onSubmit)*/} aria-label="save">
        <SaveIcon />
      </IconButton>
      </> : <CircularProgress /> }
    </>}>
		  <div className={styles.container_interactive_map}>
        <div>
			    <iframe key={random} src='/admin/mapa-interactivo-tb' style={{width: "100%", height: "80vh"}} />
		    </div>
        <div>
          <input type="hidden" id="input_editor_data" value={JSON.stringify(editorData)} />
          <div className={styles.jsonEditor}>
            <Editor 
              value={editorData}
              onChange={handleChange}
              ace={ace}
              mode="code"
              theme="ace/theme/github"
            />
          </div>
          <div>
            <Button onClick={handleRefresData} variant="contained" color="primary">Recargar</Button>
          </div>
        </div>
      </div>
    </FullwidthDialog>
  )
}