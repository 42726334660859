import { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, IconButton, Grid, InputLabel, MenuItem, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
import FullwidthDialog from "../../../components/Dialog/FullwidthDialog";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaImage, ValidationSchemaImages } from "../services/SchemaValidation";
import { useMutation } from "react-query";
import { editImages } from "../../../services/api/Floor";
import { PropertyImageCard } from "../../../components/Card";
import { useTheme } from '@mui/material/styles';
import styles from './styles.module.css'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import MediaModal from '../../Media/MediaModal'
import GenerateAutomaticGallery from '../../Media/GenerateAutomaticGallery'
import { ImageSearch as ImageSearchIcon, Save as SaveIcon } from '@mui/icons-material'

export default function Images({ open, setOpen, floor, reportAction }) {
  const { _id, photos_floor } = floor
  const theme = useTheme()
  const formAddEditRef = useRef()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  let schemaImage = { name: "", image: "", description: "" }
  const [isLoading, setIsLoading] = useState(false)
  const [instanceImage, setInstanceImage] = useState({})
  const [mode, setMode] = useState(true)
  const [editPosition, setEditPosition] = useState(null)
  const [openMediaModal, setOpenMediaModal] = useState(false)
  const [openGenerateAG, setOpenGenerateAG] = useState(false)
  const [inputMediaSelected, setInputMediaSelected] = useState(false)
  let { reset, handleSubmit, register, formState: { errors }, setValue} = useForm({ resolver: yupResolver(ValidationSchemaImage) })
  let { /*handleSubmit: hTBP, formState: { errors: eTBP },*/ control: cTBP, setValue: svTBP } = useForm({ 
    resolver: yupResolver(ValidationSchemaImages), 
    defaultValues: { 'photos_floor': [] } 
  })
  const {fields: fTBP, append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP} = useFieldArray({ 
    name: 'photos_floor', control: cTBP 
  })

  useEffect(() => { 
    if(open) { 
      //setData(property)
      svTBP("photos_floor", floor.photos_floor)
    } 
  }, [open])

  const handleClose = () => { handleCancelEditImage(); svTBP('photos_floor', []); setOpen(false) }

  const mutation = useMutation(editImages, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction('images', false, { message: 'Error al editar los datos'}, false)
      } else { 
        handleClose()
        reportAction('images', false, { }, true) 
      }
    }
  })

  const onSubmit = () => {
    setIsLoading(true)
    mutation.mutate({id: _id, params: fTBP})
  }

  const onAddImages = (params) => {
    aTBP(params)
    reset(schemaImage)
    setInstanceImage(schemaImage)
  }

  const handleRemoveImage = (position) => { rmvTBP(position) }

  const handleEditImage = (position) => { 
    setMode(false)
    let schemaTmp = { name: fTBP[position].name, image: fTBP[position].image, description: fTBP[position].description}
    setValue("name", fTBP[position].name) 
    setValue("image", fTBP[position].image)
    setValue("description", fTBP[position].description)
    setInstanceImage(schemaTmp); setEditPosition(position)
    if(matchesMD) { formAddEditRef.current.scrollIntoView({ behavior: 'smooth' }) }
  }

  const handleSaveEdit = () => {
    updTBP(editPosition, instanceImage)
    setInstanceImage({}); reset(schemaImage); setMode(true)
  }

  const handleCancelEditImage = () => {
    setInstanceImage({}); reset(schemaImage); setMode(true)
  }

  const handleChangeData = (event) => { setInstanceImage({...instanceImage, [event.target.name]: event.target.value}) }
  const handleChangeUpPosition = (position) => { mvTBP(position, position - 1) }
  const handleChangeDownPosition = (position) => { mvTBP(position, position + 1) }
  
  const handleOpenMedia = (input) => { setInputMediaSelected(input); setOpenMediaModal(true) }

  const handleMediaSelected = (imgUrl) => {
    setInstanceImage({...instanceImage, [inputMediaSelected]: imgUrl})
    setValue("image", imgUrl)
  }

  const handlerLoadImages = (images) => {
    images.forEach(image => {
      let schemaTmp = { name: "", image: image, description: ""}
      aTBP(schemaTmp)
    })
  }


  return (<>
    <FullwidthDialog open={open} changeOpen={handleClose} title={`Editar imagenes - ${floor.name}`} actions={<>
      { !isLoading ? <>
      <IconButton autoFocus color="inherit" onClick={onSubmit} aria-label="save"> <SaveIcon /> </IconButton> </> : <CircularProgress /> }
    </>}>
      <div className={styles.box_container}>
          <Paper style={{ padding: 24, borderRadius: 16 }}>
            <Grid container spacing={2}>
            { (fTBP.length > 0) ? (
            fTBP.map((item, i) => (
              <Grid item xs={12}>
                <PropertyImageCard key={i} index={i} totalItems={fTBP.length} image={item.image} name={item.name} description={item.description} edit={() => handleEditImage(i)} remove={() => handleRemoveImage(i)} changeUpPosition={() => handleChangeUpPosition(i)} changeDownPosition={() => handleChangeDownPosition(i)} />
              </Grid>
            )) ) : <Grid item xs={12}> <Typography variant="inherit">No hay imagenes agregadas</Typography> </Grid> }
            </Grid>
          </Paper>
          <Paper style={{ padding: 24, borderRadius: 16, position: "sticky", top: 80 }} ref={formAddEditRef}>
            <Typography variant="inherit">{ mode ? "Agregar" : "Editar" } imagen</Typography><br />
            <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
            <TextField id='name' fullWidth margin="dense" type="text" name='name'
              value={instanceImage.name || ""}
              {...register(`name`)} 
              error={errors.name ? true: false}
              onChange={handleChangeData} />
            <ValidateErrorMessage message={errors.name?.message} />
            { (instanceImage.image !== undefined && instanceImage.image !== null && instanceImage.image !== "") && <div style={{ textAlign: "center" }}>
              <img alt="preview-url" src={instanceImage.image} style={{ width: "100%", maxWidth: "250px" }}/>
            </div> }
            <InputLabel htmlFor='image' required style={{ fontSize: 14 }}>Url de la imagen</InputLabel>
            <TextField id='image' fullWidth margin="dense" type="text" name='image'
              value={instanceImage.image || ""}
              {...register(`image`)} 
              error={errors.image ? true: false}
              onChange={handleChangeData} />
            <ValidateErrorMessage message={errors.image?.message} />
            <IconButton onClick={() => handleOpenMedia("image")}> <ImageSearchIcon color="info" /> </IconButton><br />
            <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
            <TextField id='description' fullWidth margin="dense" type="text" name='description'
              value={instanceImage.description || ""}
              {...register(`description`)} 
              error={errors.description ? true: false}
              onChange={handleChangeData} />
            <ValidateErrorMessage message={errors.description?.message} />

            <IconButton onClick={() => setOpenGenerateAG(true)}> <ImageSearchIcon color="info" /> </IconButton><br />

            {!isLoading ? <div style={{ textAlign: "right" }}>
              { (!mode) ? <>
                <Button onClick={handleCancelEditImage} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
                <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
              </> :  <Button onClick={handleSubmit(onAddImages)} variant="contained" color="primary"> Agregar </Button> }
            </div> : <></> }
          </Paper>
      </div>
    </FullwidthDialog>
    <MediaModal open={openMediaModal} setOpen={setOpenMediaModal} mediaSelected={handleMediaSelected} path={`pisos/${floor._id}/photos`} />
    <GenerateAutomaticGallery open={openGenerateAG} setOpen={setOpenGenerateAG} setImages={ handlerLoadImages } path={`pisos/${floor._id}/photos`} />
  </>)
}