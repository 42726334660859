import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getProperties = () =>
  fetch(
    `${API_BASE_URL}/property`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN
    }
  )
  .then(res => res.json())

export const getPropertyTypes = () =>
  fetch(
    `${API_BASE_URL}/property-types`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN
    }
  )
  .then(res => res.json())

  export const getPropertyAmenities = () =>
  fetch(
    `${API_BASE_URL}/property-amenities`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN
    }
  )
  .then(res => res.json())


/* Saves and Edits */

export const saveProperty = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/property`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  );
  return await res.json();
}

export const editProperty = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/property/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  );
  return await res.json();
}

export const editPropertyLocation = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/location`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  );
  return await res.json();
}

export const editPropertyImageLogos = async (data) => {
  const { id, params } = data
  params.extra_logos.forEach(el => {
    delete el['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/image-logos`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyImages = async (data) => {
  const { id, params, typeImages } = data
  params.forEach(param => {
    delete param['id']
  })
  let imageType = "";
  switch (typeImages) {
    case 0:
      imageType = "towerbuilding-photo"
      break;
    case 1:
      imageType = "interior-photo"
      break;
    case 2:
      imageType = "exterior-photo"
      break;
    case 3:
      imageType = "additional-photo"
      break;
    default:
      break;
  }
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/${imageType}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyAmenities = async (data) => {
  const { id, params } = data
  params.forEach(el => {
    delete el['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/amenities`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyFacilities = async (data) => {
  const { id, params } = data
  params.forEach(el => {
    delete el['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/facilities`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyMobilities = async (data) => {
  const { id, params } = data
  params.forEach(el => {
    delete el['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/mobilities`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyTenants = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/tenants`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyWeb = async (data) => {
  const { id, params } = data
  params.texts.forEach(el => {
    delete el['id']
  })
  params.circles.forEach(el => {
    delete el['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/web`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyBasePrice = async (data) => {
  const { id, params } = data
  params.forEach(el => {
    delete el['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/base-price`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editInteractiveMap = async (data) => {
  const { id, params } = data
  /*params.forEach(param => {
    delete param['id']
  })*/
  const res = await fetch(
    `${API_BASE_URL}/property/${id}/interactive-map`,
    {
      method: "PUT",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  
  return await res.json();
}