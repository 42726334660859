import * as Yup from 'yup'

// Property General Data validation
export const ValidationSchema = Yup.object().shape({
  property_name: Yup.string()
		.required("Campo requerido"),
  tradename: Yup.string()
		.required("Campo requerido"),
  property_type: Yup.array()
    .min(1, "Se debe seleccionar al menos una opción")
    .typeError("Se debe seleccionar al menos una opción")
    .required("Campo requerido"),
  slug: Yup.string()
		.required("Campo requerido"),
  total_rent_square_meters: Yup.number()
		.typeError("Campo debe ser numérico")
		.required("Campo requerido"),
  towers_buildings_number: Yup.number()
		.typeError("Campo debe ser numérico")
		.required("Campo requerido"),
  parking_spaces: Yup.number()
		.typeError("Campo debe ser numérico")
		.required("Campo requerido"),
  rent_currency: Yup.string(),
  brochure_digital: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("Campo requerido"),
      url: Yup.string().required("Campo requerido")
    })
  )
})

// Property Image and logos validation
export const ValidationSchemaImageLogo = Yup.object().shape({
  property_photo: Yup.string()
		.required("Campo requerido"),
  logo: Yup.string()
		.required("Campo requerido"),
  extra_logos: Yup.array().of(
    Yup.object().shape({
      image: Yup.string().required("Campo requerido"),
      identifier: Yup.string().required("Campo requerido")
    })
  )
})

export const ValidationSchemaExtraLogo = Yup.object().shape({
  image: Yup.string().required("Campo requerido"),
  identifier: Yup.string().required("Campo requerido")
})

// Location validation
export const ValidationSchemaLocation = Yup.object({
  city: Yup.string()
    .required("Campo requerido"),
  state: Yup.string()
    .required("Campo requerido"),
  address: Yup.string()
    .required("Campo requerido"),
  
  georeference: Yup.object().shape({
    latitude: Yup.number()
      .typeError("Campo requerido")
      .required("Campo requerido"),
    longitude: Yup.number()
      .typeError("Campo requerido")
      .required("Campo requerido"),
  }),
  google_maps: Yup.string()
    .required("Campo requerido"),
  facebook: Yup.string()
    .required("Campo requerido"),
  waze: Yup.string().nullable(),
  phone: Yup.string()
    .required("Campo requerido"),
  whatsapp: Yup.string()
    .required("Campo requerido"),
  email: Yup.string()
    .required("Campo requerido"),
  landing_page: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("Campo requerido"),
      url: Yup.string().required("Campo requerido")
    })
  )
})

// Images/Photos validation
export const ValidationSchemaImage = Yup.object().shape({
  name: Yup.string().nullable(),
  image: Yup.string().required("Campo requerido"),
  description: Yup.string().nullable()
})

export const ValidationSchemaImages = Yup.object({
  images: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable(),
      image: Yup.string().required("Campo requerido"),
      description: Yup.string().nullable()
    })
  )
})

// Amenities validation
export const ValidationSchemaAmenity = Yup.object().shape({
  description: Yup.string().required("Campo requerido"),
  icon: Yup.string().required("Campo requerido")
})

export const ValidationSchemaAmenities = Yup.object({
  amenities: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Campo requerido"),
      icon: Yup.string().required("Campo requerido")
    })
  )
})

// Facilities validation
export const ValidationSchemaFacility = Yup.object().shape({
  description: Yup.string().required("Campo requerido"),
  icon: Yup.string().required("Campo requerido")
})

export const ValidationSchemaFacilities = Yup.object({
  facilities: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Campo requerido"),
      icon: Yup.string().required("Campo requerido")
    })
  )
})

// Mobilities validation
export const ValidationSchemaMobility = Yup.object().shape({
  description: Yup.string().required("Campo requerido"),
  icon: Yup.string().required("Campo requerido")
})

export const ValidationSchemaMobilities = Yup.object({
  mobilities: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Campo requerido"),
      icon: Yup.string().required("Campo requerido")
    })
  )
})

// Property Image and logos validation
export const ValidationSchemaWeb = Yup.object().shape({
  texts: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required("Campo requerido"),
      identifier: Yup.string().required("Campo requerido")
    })
  ),
  circles: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required("Campo requerido"),
      value: Yup.string().required("Campo requerido")
    })
  )
})

export const ValidationSchemaWebText = Yup.object().shape({
  text: Yup.string().required("Campo requerido"),
  identifier: Yup.string().required("Campo requerido")
})

export const ValidationSchemaWebCircle = Yup.object().shape({
  text: Yup.string().required("Campo requerido"),
  value: Yup.string().required("Campo requerido")
})

// Property tenants validation
export const ValidationSchemaTenants = Yup.object({
  tenants: Yup.array().of(Yup.string())
})

// Base price validation
export const ValidationSchemaBasePrice = Yup.object().shape({
  starting_price: Yup.number().typeError("Campo debe ser numérico").required("Campo requerido"),
  cam: Yup.number().typeError("Campo debe ser numérico").required("Campo requerido"),
  currency: Yup.string().required("Campo requerido")
})

export const ValidationSchemaBasePrices = Yup.object({
  base_price: Yup.array().of(
    Yup.object().shape({
      starting_price: Yup.number().typeError("Campo debe ser numérico").required("Campo requerido"),
      cam: Yup.number().typeError("Campo debe ser numérico").required("Campo requerido"),
      currency: Yup.string().required("Campo requerido")
    })
  )
})

//
export const ValidationSchemaTowerBuilding = Yup.object({
  property: Yup.string()
    .required("Campo requerido"),
  name: Yup.string()
    .required("Campo requerido"),
  rent_meters: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  architectural_plan: Yup.string()
    .required("Campo requerido"),
  price_square_meter: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido")
})
