//import { useContext } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from './domain/Dashboard';
//import { AuthContext } from './context/AuthContext';
import AdminLayout from './components/Layout/AdminLayout';
//import LoginLayout from './components/Layout/LoginLayout';
import Login from './domain/Login';
import ThemeMUIProvider from './theme/MainTheme';
import Properties from './domain/Properties';
import PropertyAmenities from './domain/PropertyAmenity';
import PropertyFacilities from './domain/PropertyFacility';
import PropertyMobilities from './domain/PropertyMobility';
import TowerBuilding from './domain/TowerBuilding';
import Tenants from './domain/Tenants';
import TowerBuildingIM from './domain/InteractiveMap/TowerBuildingIM';
import Floor from './domain/Floor';
import WebPage from './domain/WebPage';

function App() {
  //const { isAuth } = useContext(AuthContext)

  //function PrivateRoute() { return isAuth ? <AdminLayout /> : <Navigate to="/login" /> }

  return (
    <ThemeMUIProvider>
      <BrowserRouter>
        <Routes>
          {/*isAuth ? <>*/}
          <Route path="/admin/*" element={ <AdminLayout /> }>
            <Route index element={ <Login /> } />
            <Route path="dashboard" element={ <Dashboard /> } />
            <Route path="paginas-web" element={ <WebPage /> } />
            <Route path="propiedades" element={ <Properties /> } />
            <Route path="torres-edificios" element={ <TowerBuilding /> }/>
            <Route path="pisos" element={ <Floor /> }/>
            <Route path="amenidades" element={ <PropertyAmenities /> }/>
            <Route path="instalaciones" element={ <PropertyFacilities /> }/>
            <Route path="movilidades" element={ <PropertyMobilities /> }/>
            <Route path="inquilinos" element={ <Tenants /> }/>
            <Route path="mapa-interactivo-tb" element={ <TowerBuildingIM /> }/>
            {/*<Route
              path="*"
              element={ <main style={{ padding: "1rem" }}> <p>There´s nothing here!</p> </main> }
            />*/}         
          </Route>
          <Route path="*" element={<Navigate to="/admin" />} />
          {/*</> :
          <Route path="/" element={ <LoginLayout /> }>
            <Route path="acceso" element={ <Login /> } />
            <Route path="/" element={ <Navigate to="/acceso" /> } />
            <Route
              path="*"
              //element={ <main style={{ padding: "1rem" }}> <p>There´s nothing here!</p> </main> }
              element={<Navigate to="/acceso" />}
            />
          </Route>
          */}
        </Routes>
      </BrowserRouter>
    </ThemeMUIProvider>
  );
}

export default App;
