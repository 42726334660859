import styles from './estilos.module.css'

export default function TypeOne({ name, logo, image, actions }) {
  
  return (
    <div className={styles.paper}>
      <div className={styles.header}>
        <div className={styles.image_decorator}></div>
        <div className={styles.image_container}>
          <img className={styles.image} alt="icon" src={logo}/>
        </div>
        <div className={styles.image_backgroud_filter}></div>
        <span className={styles.image_backgroud_container}>
          <span className={styles.image_backgroud_wrapper}>
            <img className={styles.image_backgroud} alt="prueba" src={image}/>
          </span>
        </span>
      </div>
      <div className={styles.info_container}>
        <div className={ styles.name }> { name } </div>
        <hr className={styles.divider} />
        <div className={styles.actions}> {actions} </div>
      </div>
    </div>
  )
}