import * as React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

export default function SimpleDataCard({ title, subtitle, actions }) {

  return (
    <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6"> {title} </Typography>
          <Typography component="div" variant="body1"> {subtitle} </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          {actions}
        </Box>
      </Box> 
    </Card>
  );
}