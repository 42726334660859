import { Snackbar } from "@mui/material"

export const SnackbarGlobal = ({ vertical, horizontal, open, close, message }) => {

  return (
    <Snackbar
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={open}
      autoHideDuration={5000}
      onClose={close}
      message={message}
      key={`${vertical}-${horizontal}-${message}`}
    />
  )
}