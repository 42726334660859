import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "../services/SchemaValidation"
import { useMutation } from "react-query";
import { useQuery } from "react-query"
import { getPropertyTypes, editProperty } from "../../../services/api/Property"
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, TextField  } from "@mui/material";
import DialogC from '../../../components/Dialog/index'

export default function Edit({ open, setOpen, property, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({})
  const [valueRG, setValueRG] = useState([]);
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchema) 
  })
  const { isLoading: ptLoading, isFetching: ptFetching, refetch: ptRefetch, error: ptError, data: propertyTypes } = useQuery(
    'propertyTypesList', 
    getPropertyTypes, 
    { refetchOnWindowFocus: false, enabled: false } 
  )

  useEffect(() => { 
    if(open) { 
      setData(property)
      const keys = ["property_name", "tradename", "slug", "total_rent_square_meters", "towers_buildings_number", "parking_spaces"]
      for (let index = 0; index < keys.length; index++) { setValue(keys[index], property[keys[index]]) }
      ptRefetch()
    } 
  }, [open])

  const mutation = useMutation(editProperty, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('edit', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('edit', false, { }, true) }
    }
  })

  const onSubmit = (params) => { 
    params.property_type = params.property_type.filter(pt => pt !== false)
    console.log("Params:", params)
    setIsLoading(true)
    mutation.mutate({id: property._id, params: params})
  }

  useEffect(() => {
    if(propertyTypes !== undefined && propertyTypes != null) {
      let tempObject = []
      propertyTypes.data.forEach(element => {
        let found = property.property_type.find(type => type === element._id)
        found === undefined ? element.selected = false : element.selected = true
        tempObject.push(element)
      });      
      setValueRG(tempObject)
    }
  }, [propertyTypes])

  const handleChangeRG = (event) => {
    let findIndex = valueRG.findIndex(element => element._id === event.target.value)
    let newArr = [...valueRG]
    newArr[findIndex].selected ? newArr[findIndex].selected = false : newArr[findIndex].selected = true
    setValueRG(newArr)
  }

  const handleOnChangeData = (event) => { 
    setData({...data, [event.target.name]: event.target.value}) 
  }

  const handleClose = () => { reset(); setData({}); setValueRG([]); setOpen(false) }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(onSubmit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  if(ptLoading || ptFetching) return <div>Cargando...</div>
  if(ptError) return <div> Ha ocurrido un error </div>

  return (
    <DialogC open={open} title={`Editar`} actions={<Actions />}>
      <InputLabel htmlFor='property_name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
      <TextField id='property_name' fullWidth margin="dense" type="text" name='property_name'
        value={data.property_name || ""}
        {...register(`property_name`)} 
        error={errors.property_name ? true: false}
        onChange={handleOnChangeData} />
      <ValidateErrorMessage message={errors.property_name?.message} /><br />
      <InputLabel htmlFor='tradename' required style={{ fontSize: 14 }}>Nombre comercial</InputLabel>
      <TextField id='tradename' fullWidth margin="dense" type="text" name='tradename'
        value={data.tradename || ""}
        {...register(`tradename`)} 
        error={errors.tradename ? true: false}
        onChange={handleOnChangeData} />
      <ValidateErrorMessage message={errors.tradename?.message} /><br />
      <FormControl>
        <FormLabel style={{ fontSize: 14 }}>Tipo de Propiedad</FormLabel>
        <FormGroup aria-label="position" onChange={handleChangeRG}>
          { valueRG.map((type, index) => (
            <FormControlLabel key={`${index}-${type._id}`} control={
              <Checkbox value={type._id} checked={type.selected} name="property_type" {...register(`property_type.${index}`)} />
            } label={type.name} labelPlacement="end" />
          ))}
        </FormGroup>
      </FormControl>
      <ValidateErrorMessage message={errors.property_type?.message} /><br />
      <InputLabel htmlFor='slug' required style={{ fontSize: 14 }}>Slug</InputLabel>
      <TextField id='slug' fullWidth margin="dense" type="text" name='slug'
        value={data.slug || ""}
        {...register(`slug`)} 
        error={errors.slug ? true: false}
        onChange={handleOnChangeData} />
      <ValidateErrorMessage message={errors.slug?.message} /><br />
      <InputLabel htmlFor='total_rent_square_meters' required style={{ fontSize: 14 }}>Total de metros cuadrados rentables</InputLabel>
      <TextField id='total_rent_square_meters' fullWidth margin="dense" type="number" name='total_rent_square_meters' 
        value={data.total_rent_square_meters || ""}
        {...register(`total_rent_square_meters`)} 
        error={errors.total_rent_square_meters ? true: false}
        onChange={handleOnChangeData} />
      <ValidateErrorMessage message={errors.total_rent_square_meters?.message} /><br />
      <InputLabel htmlFor='towers_buildings_number' required style={{ fontSize: 14 }}>Total de torres / edificios</InputLabel>
      <TextField id='towers_buildings_number' fullWidth margin="dense" type="number" name='towers_buildings_number' 
        value={data.towers_buildings_number || ""}
        {...register(`towers_buildings_number`)} 
        error={errors.towers_buildings_number ? true: false}
        onChange={handleOnChangeData} />
      <ValidateErrorMessage message={errors.towers_buildings_number?.message} /><br />
      <InputLabel htmlFor='parking_spaces' required style={{ fontSize: 14 }}>Total de espacios de estacionamiento</InputLabel>
      <TextField id='parking_spaces' fullWidth margin="dense" type="number" name='parking_spaces' 
        value={data.parking_spaces || ""}
        {...register(`parking_spaces`)} 
        error={errors.parking_spaces ? true: false}
        onChange={handleOnChangeData} />
      <ValidateErrorMessage message={errors.parking_spaces?.message} /><br />
    </DialogC>
  )
}