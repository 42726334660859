import { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import DialogC from '../../../components/Dialog'
import { useMutation } from 'react-query'
import { deleteWebPage } from "../../../services/api/WebPage";

export default function Delete({ open, setOpen, webPage, reportAction  }) {
  const [isLoading, setIsLoading] = useState(false)

  const mutation = useMutation(deleteWebPage, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('delete', false, { message: 'Error al eliminar los datos'}, false)
      } else { reportAction('delete', false, { }, true) }
    }
  })

  const handleDelete = () => {
    setIsLoading(true)
    mutation.mutate(webPage._id)
  }

  const handleClose = () => { setOpen(false) }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="error" onClick={handleDelete}>Eliminar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <DialogC open={open} title="Eliminar" actions={<Actions />} >
      <p>Esta seguro de querer eliminar este elemento:</p>
      <p><b>{ webPage.title }</b></p>
    </DialogC>
  </>)
}