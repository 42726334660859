import { Button, CircularProgress, IconButton } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import DialogC from "../../../components/Dialog";
import { storage } from "../../../firebase/firebase"
import { ref, getDownloadURL, uploadBytesResumable, list, deleteObject } from "firebase/storage";
import styles from './styles.module.css'
import ToolBar from "../../../components/ToolBar";

export default function GenerateAutomaticGallery({ open, setOpen, setImages, path }) {
  const [isLoading, setIsLoading] = useState(false)
  const [imagesListFS, setImagesListFS] = useState([])
  const [imagesSelected, setImagesSelected] = useState([])
  const [dataFilter, setDataFilter] = useState(null);

  const handleClose = () => { 
    setIsLoading(false);
    setImagesListFS([]); 
    setImagesSelected([]);
    setOpen(false) 
  }

  useEffect(() => { if(open) handleGetImagesListFromPath() }, [open])

  const handleGetImagesListFromPath = async() => {
    setImagesListFS([])
    setIsLoading(true)
    const listRef = ref(storage, path);
    list(listRef, { maxResults: 100 }).then((resp) => {
      let promises = resp.items.map((imageRef) => getDownloadURL(imageRef));
      Promise.all(promises).then((urls) => { 
        setImagesListFS(urls)
        setDataFilter(urls)
        setIsLoading(false)
      })
    }).catch((error) => { console.log("Error", error)  });
  }
  
  const handleGetNameImage = (img) => {
    const sep1 = img.split("?")
    const sep2 = sep1[0].split("%2F")
    return sep2[sep2.length - 1]
  }

  const handleSelectImage = (img) => { 
    if(imagesSelected.includes(img)) {
      setImagesSelected(imagesSelected.filter(image => image !== img))
    } else {
      setImagesSelected([...imagesSelected, img])
    }
  }

  const handlerSelectAllImages = () => { 
    if(dataFilter !== null) {
      setImagesSelected(dataFilter)  
    } else {
      setImagesSelected(imagesListFS)
    }
  }

  const handleSearch = (e) => {
    const dataFilter = imagesListFS.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const Actions = () => (<>
    { !isLoading ? <> 
      <Button onClick={handleClose}>Cancel</Button> 
      { imagesSelected.length > 0 &&
      <Button variant="contained" color="primary" onClick={() => {setImages(imagesSelected); handleClose();} }>Seleccionar</Button> }
    </> : <CircularProgress /> }
  </>)

  return (<>
    <DialogC open={open} title="Generar galeria rápida" maxWidth="lg" actions={<Actions />}>
      <div className={ styles.toolbar_container }>
        <ToolBar searchFunction={handleSearch} />
      </div>
      <div className={styles.container_main}>
        <div className={styles.media_container_image_cards}>
        { !isLoading ? (
          (dataFilter !== null) ? 
            dataFilter.map((img, index) => 
            <div key={index} className={ imagesSelected.includes(img) ? styles.media_image_card_selected : styles.media_image_card}>
              <img src={img} className={styles.media_image_card_image} onClick={() => handleSelectImage(img)}/> <br />
              {handleGetNameImage(img)} <br />
            </div> ) 
          : <div>No hay archivos</div>
          ) : <CircularProgress />
        }
        </div>
        <div>
          <Button onClick={handlerSelectAllImages}>Seleccionar Todos</Button>
        </div>
      </div>
    </DialogC>
  </>)
}