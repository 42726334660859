import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl"; 

export const getTowersBuildingsByProperty = async (property) => {
  const res = await fetch(
    `${API_BASE_URL}/towers-buildings/towersbuildings-by-property`,
    {
      method: "POST",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify({ property: property })
    }
  )
  
  return await res.json();
}

export const saveTowerBuilding = async (data) => {
  const res = await fetch(
    `${API_BASE_URL}/towers-buildings`,
    {
      method: "POST",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(data)
    }
  )
  
  return await res.json();
}

export const editTowerBuilding = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/towers-buildings/${id}`,
    {
      method: "PUT",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  
  return await res.json();
}