import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl"; 

export const getCitiesByState = async (state) => {
  const res = await fetch(
    `${API_BASE_URL}/cities/cities-by-state`,
    {
      method: "POST",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify({ state: state })
    }
  )
  
  return await res.json();
}