import { useState } from "react";
import { Button, CircularProgress, IconButton, InputLabel, Switch, TextField } from "@mui/material";
import DialogC from "../../../components/Dialog";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useMutation } from 'react-query'
import { saveTenant } from "../../../services/api/Tenant";
import MediaModal from '../../Media/MediaModal'
import ImageSearchIcon from '@mui/icons-material/ImageSearch';

export default function Add({ open, setOpen, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataTenant, setDataTenant] = useState({})
  const [openMediaModal, setOpenMediaModal] = useState(false)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  const mutation = useMutation(saveTenant, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('add', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('add', false, { }, true) }
    }
  })

  const handleAdd = (params) => { 
    console.log("prueba", params)
    setIsLoading(true)
    mutation.mutate(params)
  }
  
  const handleClose = () => { reset(); setDataTenant({}); setOpen(false) }

  const handleOnChangeData = (event) => { 
    setDataTenant({...dataTenant, [event.target.name]: event.target.value}) 
  }

  const handleChangeActive = (event) => {
    setDataTenant({...dataTenant, active: !dataTenant.active}) 
  }

  const handleMediaSelected = (imgUrl) => {
    setDataTenant({...dataTenant, logo: imgUrl})
    setValue("logo", imgUrl) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleAdd)}>Agregar</Button>
      </> : <CircularProgress /> }
  </>)

  return (<>
    <DialogC open={open} title={`Nuevo inquilino`} actions={<Actions />}>
      <>
        <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataTenant.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='description' style={{ fontSize: 14 }}>Descripción</InputLabel>
        <TextField id='description' fullWidth margin="dense" type="text" name='description'
          multiline
          maxRows={4}
          value={dataTenant.description || ""}
          {...register(`description`)} 
          error={errors.description ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.description?.message} />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataTenant.logo || "/images/picture_empty.png"} style={{ width: "90%" }}/>
          </div>
          <div>
          <InputLabel htmlFor='logo' style={{ fontSize: 14 }}>Logo</InputLabel>
          <TextField id='logo' fullWidth margin="dense" type="text" name='logo'
            value={dataTenant.logo || ""}
            {...register(`logo`)} 
            error={errors.logo ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.logo?.message} />
          <IconButton onClick={() => setOpenMediaModal(true)}> <ImageSearchIcon color="info" /> </IconButton>
          </div>
        </div>
        <br />
        <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Activo?</InputLabel>
        <Switch edge="end" inputProps={{ 'aria-labelledby': `switch-list-label-active` }}
          onClick={handleChangeActive}
          checked={dataTenant.active || false} 
          {...register(`active`)} 
        />
      </>
    </DialogC>
    <MediaModal open={openMediaModal} setOpen={setOpenMediaModal} mediaSelected={handleMediaSelected} path="tenants" />
  </>)
}