import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaAmenities } from "../services/SchemaValidation"
import { useMutation } from "react-query";
import { editPropertyAmenities } from "../../../services/api/Property";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import FullwidthDialog from '../../../components/Dialog/FullwidthDialog'
import { Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import MediaModal from '../../Media/MediaModal'
import styles from '../styles.module.css'
import ExtraLogoCard from "../../../components/Card/ExtraLogoCard";
import AddEditForm from "./AddEditForm";

export default function Amenities({ open, setOpen, property, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({})
  const [openMediaModal, setOpenMediaModal] = useState(false)
  const [inputMediaSelected, setInputMediaSelected] = useState(false)
  const [mode, setMode] = useState(true)
  const [editPosition, setEditPosition] = useState(null)
  const [addEditFormSetValue, setAddEditFormSetValue] = useState(null)
  //Property image, logo and extra logos
  let { handleSubmit, register, formState: { errors }, control, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaAmenities),
    defaultValues: { 'amenities': [] } 
  })
  const { fields: fieldsEL, append: appendEL, remove: removeEL, update: updateEL } = useFieldArray({ 
    name: 'amenities', control 
  })

  useEffect(() => { 
    if(open) { 
      setData(property)
      setValue("amenities", property.property_amenities)
    } 
  }, [open])

  const mutation = useMutation(editPropertyAmenities, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction('amenities', false, { message: 'Error al editar los datos'}, false)
      } else { 
        handleClose()
        reportAction('amenities', false, { }, true) 
      }
    }
  })

  const onSubmit = (params) => {
    console.log("Params:", params)
    setIsLoading(true)
    mutation.mutate({id: property._id, params: fieldsEL})
  }

  const handleClose = () => { handleResetAddEditForm(); setValue('images', []); setData({}); setOpen(false) }

  const handleOpenMedia = (input) => { setInputMediaSelected(input); setOpenMediaModal(true) }

  const handleMediaSelected = (imgUrl) => {
    setAddEditFormSetValue([{ reference: "icon", value: imgUrl }])
  }

  const handleRemoveExtraLogo = (position) => { removeEL(position) }

  const handleEditExtraLogo = (position) => { 
    setMode(false)
    setAddEditFormSetValue([
      { reference: "icon", value: fieldsEL[position].icon },
      { reference: "description", value: fieldsEL[position].description },
    ]) 
    setEditPosition(position)
  }

  const handleResetAddEditForm = () => { setAddEditFormSetValue([{ ref: "reset", value: "" }]); setMode(true) }

  const Actions = ({ index }) => (<>
    <IconButton aria-label="edit" onClick={ () => handleEditExtraLogo(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveExtraLogo(index) }> <DeleteIcon color='error'/> </IconButton>
  </>)

  return (<>
    <FullwidthDialog open={open} changeOpen={handleClose} title="Editar amenidades" actions={<>
      { !isLoading ? <>
      <IconButton autoFocus color="inherit" onClick={handleSubmit(onSubmit)} aria-label="save"> <SaveIcon /> </IconButton> </> : <CircularProgress /> }
    </>}>
      <div className={styles.imagelogos_container}>
        <div>
          { (fieldsEL.length > 0) ? (
          fieldsEL.map((item, i) => (
            <Grid item xs={12}>
              <ExtraLogoCard key={i} image={item.icon} identifier={item.description} actions={<Actions index={i}/>}/><br />
            </Grid>
          )) ) : <Grid item xs={12}> No hay imagenes agregadas </Grid> }
        </div>
        <div>
          <AddEditForm isLoading={isLoading} editPosition={editPosition} mode={mode} setMode={setMode} handleOpenMedia={handleOpenMedia} updateArray={updateEL} appendArray={appendEL} setValues={addEditFormSetValue}/>
        </div>
      </div>
    </FullwidthDialog>
    <MediaModal open={openMediaModal} setOpen={setOpenMediaModal} mediaSelected={handleMediaSelected} path={`${property._id}/amenidades`} />
  </>)
}