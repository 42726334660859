import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl"; 

export const getFloorsByTowerBuilding = async (towerbuilding) => {
  const res = await fetch(
    `${API_BASE_URL}/floors/floors-by-towerbuilding`,
    {
      method: "POST",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify({ towerbuilding: towerbuilding })
    }
  )
  
  return await res.json();
}

export const saveFloor = async (data) => {
  const res = await fetch(
    `${API_BASE_URL}/floors`,
    {
      method: "POST",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(data)
    }
  )
  
  return await res.json();
}

export const editFloor = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/floors/${id}`,
    {
      method: "PUT",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  
  return await res.json();
}

export const editSpaces = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/floors/${id}/spaces`,
    {
      method: "PUT",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  
  return await res.json();
}

export const editImages = async (data) => {
  const { id, params } = data
  params.forEach(param => {
    delete param['id']
  })
  const res = await fetch(
    `${API_BASE_URL}/floors/${id}/images`,
    {
      method: "PUT",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  
  return await res.json();
}

export const editInteractiveMap = async (data) => {
  const { id, params } = data
  /*params.forEach(param => {
    delete param['id']
  })*/
  const res = await fetch(
    `${API_BASE_URL}/floors/${id}/interactive-map`,
    {
      method: "PUT",
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  
  return await res.json();
}