import { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, InputLabel, TextField } from "@mui/material";
import DialogC from "../../../components/Dialog";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaCodes } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useMutation } from 'react-query'
import { editWebPageCodes } from "../../../services/api/WebPage";
import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html';

export default function Codes({ open, setOpen, webPage, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataWebPageCodes, setDataWebPageCodes] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchemaCodes)
  })

  useEffect(() => {
    if(open) {
      setDataWebPageCodes({
        head: webPage.codes.head ? webPage.codes.head : "",
        body: webPage.codes.body ? webPage.codes.body : "",
      })
      const keys = ["head", "body"]
      for (let index = 0; index < keys.length; index++) {
        setValue(keys[index], webPage.codes[keys[index]])
      }
    }
  }, [open])

  const mutation = useMutation(editWebPageCodes, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('codes', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('codes', false, { }, true) }
    }
  })

  const handleEdit = (params) => { 
    console.log("prueba", params)
    console.log("id", webPage._id)
    setIsLoading(true)
    mutation.mutate({id: webPage._id, params: params})
  }

  const handleClose = () => { reset(); setDataWebPageCodes({}); setOpen(false) }

  const handleOnChangeData = (event) => { 
    setDataWebPageCodes({...dataWebPageCodes, [event.target.name]: event.target.value}) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)
  //https://cdn-icons-png.flaticon.com/512/6133/6133429.png
  //https://cloudinary.com/pricing
  return (<>
    <DialogC open={open} title={`Editar códigos - ${webPage.title}`} actions={<Actions />}>
      <>
        { open && <>
        <InputLabel htmlFor='head' required style={{ fontSize: 14 }}>Head codes</InputLabel>
        <CodeMirror style={{ fontSize: 15 }}
          placeholder={`Agrega tu código`}
          value={dataWebPageCodes.head || ""}
          height="300px"
          theme='dark'
          extensions={[html()]}
          onChange={(value, viewUpdate) => {
            console.log('value:', value);
            setDataWebPageCodes({...dataWebPageCodes, head: value}) 
            setValue("head", value)
          }}
        />
        <ValidateErrorMessage message={errors.head?.message} />
        <br />
        <InputLabel htmlFor='body' required style={{ fontSize: 14 }}>Body codes</InputLabel>
        <CodeMirror style={{ fontSize: 15 }}
          placeholder={`Agrega tu código`}
          value={dataWebPageCodes.body || ""}
          height="300px"
          theme='dark'
          extensions={[html()]}
          onChange={(value, viewUpdate) => {
            console.log('value:', value);
            setDataWebPageCodes({...dataWebPageCodes, body: value}) 
            setValue("body", value)
          }}
        />
        <ValidateErrorMessage message={errors.body?.message} />
        <br />
        </> }
      </>
    </DialogC>
  </>)
}