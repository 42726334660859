import { InputLabel, TextField } from '@mui/material'
import { ValidateErrorMessage } from '../../ValidateErrorMessage'

export default function TextFieldGroup({ 
  label, 
  inputName, 
  type="text", 
  required, 
  defaultValue,
  rhfRegister,
  inputError,
  errorMessage,
  onChange
}) {
  return (<div style={{marginBottom: 8}}>
    <InputLabel htmlFor={inputName} required={required} style={{ fontSize: 14 }}>{label}</InputLabel>
    <TextField 
      id={inputName}
      fullWidth
      margin="dense" 
      type={type}
      name={inputName}
      defaultValue={defaultValue}
      {...rhfRegister} 
      error={inputError ? true: false}
      onChange={onChange} />
    <ValidateErrorMessage message={errorMessage} />
  </div>)
}