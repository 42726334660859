import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "center",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 16,
    backgroundImage: "none",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(33, 43, 54)",
    boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;",
    zIndex: 0
  },
  header: {
    position: "relative",
    height: 90
  },
  image_decorator: {
    width: 144,
    height: 62,
    display: "inline-block",
    backgroundColor: "currentcolor",
    mask: "url(/images/shape-avatar.svg) center center / contain no-repeat", 
    zIndex: 10,
    left: 0,
    right: 0,
    bottom: -26,
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
    color: "rgb(255, 255, 255)"
  },
  image_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    fontSize: "1.25rem",
    lineHeight: 1,
    borderRadius: "50%",
    overflow: "hidden",
    userSelect: "none",
    width: 64,
    height: 64,
    zIndex: 11,
    left: 0,
    right: 0,
    bottom: -32,
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute"
  },
  image: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    objectFit: "cover",
    color: "transparent",
    textIndent: "10000px"
  },
  image_backgroud_filter: {
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(257, 177, 92, 0.8)",
    top: 0,
    zIndex: 8,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute"
  },
  image_backgroud_container: {
    width: "100%",
    lineHeight: 0,
    display: "block",
    overflow: "hidden",
    position: "relative",
    paddingTop: "calc(56.25%)"
  },
  image_backgroud_wrapper: {
    transition: "filter .3s, -webkit-filter .3s",
    inset: 0,
    lineHeight: 0,
    position: "absolute",
    backgroundSize: "cover !important"
  },
  image_backgroud: {
    color: "transparent",
    width: "100%",
    height: 90,
    objectFit: "cover",
    textAlign: "center",
    textIndent: "10000px"
  },
  info_container: {
    padding: "0px 8px 0px 8px"
  },
  name: {
    margin: "48px 0px 0px",
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: "1rem",
    fontFamily: '"Public Sans", sans-serif',
    height: 50,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box !important",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
  },
  description: {
    margin: "0px 0px 8px 0px",
    lineHeight: 1.57143,
    fontSize: "0.875rem",
    //font-family: "Public Sans", sans-serif;
    fontWeight: 400,
    color: "rgb(99, 115, 129)",
    height: 45,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box !important",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
  },
  divider: {
    margin: 0,
    flexShrink: 0,
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderBottomWidth: "thin"
  },
  actions: {
    textAlign: "center",
    padding: "8px 0px 8px 0px"
  }
}))

export default function AmenityCard({ name, description, image, actionEdit, actionDelete }) {
  const classes = useStyles()

  return (
    <div className={classes.paper}>
      <div className={classes.header}>
        <div className={classes.image_decorator}></div>
        <div className={classes.image_container}>
          <img className={classes.image} alt="icon" src={image}/>
        </div>
        <div className={classes.image_backgroud_filter}></div>
        <span className={classes.image_backgroud_container}>
          <span className={classes.image_backgroud_wrapper}>
            <img className={classes.image_backgroud} alt="prueba" src="/images/image_background_header.png"/>
          </span>
        </span>
      </div>
      <div className={classes.info_container}>
        <div className={ classes.name }> { name } </div>
        <div className={classes.description}> { description } </div>
        <hr className={classes.divider} />
        <div className={classes.actions}>
          <Tooltip title="Editar">
            <IconButton color="info" onClick={actionEdit}> <EditIcon /> </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton color="error" onClick={actionDelete}> <DeleteIcon /> </IconButton>
            </Tooltip>
        </div>
      </div>
    </div>
  )
}