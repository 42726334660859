import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  tower_building: Yup.string()
    .required("Campo requerido"),
  name: Yup.string()
    .required("Campo requerido"),
  architectural_plan: Yup.string()
    .required("Campo requerido"),
  rent_square_meters: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  useful_square_meters: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  rent_square_meters_available: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  useful_square_meters_available: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  conditioned: Yup.number()
    .typeError("Campo requerido")
    .required("Campo requerido"),
  installations: Yup.number()
    .typeError("Campo requerido")
    .required("Campo requerido"),
  furniture: Yup.number()
    .typeError("Campo requerido")
    .required("Campo requerido")
})

export const ValidationSchemaSpace = Yup.object().shape({
  rent_square_meters: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  useful_square_meters: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  status: Yup.number()
    .typeError("Campo requerido")
    .required("Campo requerido"),
  tenant: Yup.string().nullable(),
  images: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable(),
      image: Yup.string().required("Campo requerido"),
      description: Yup.string().nullable()
    })
  ),
  conditioned: Yup.number()
    .typeError("Campo requerido")
    .required("Campo requerido"),
  installations: Yup.number()
    .typeError("Campo requerido")
    .required("Campo requerido"),
  furniture: Yup.number()
    .typeError("Campo requerido")
    .required("Campo requerido"),
  //available_equipment: Yup.array().of(Yup.string())
})

export const ValidationSchemaSpaces = Yup.object({
  spaces: Yup.array().of(
    Yup.object().shape({
      rent_square_meters: Yup.number()
        .typeError("Campo debe ser numérico")
        .required("Campo requerido"),
      useful_square_meters: Yup.number()
        .typeError("Campo debe ser numérico")
        .required("Campo requerido"),
      status: Yup.boolean().required("Campo requerido"),
      tenant: Yup.string().nullable(),
      images: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().nullable(),
          image: Yup.string().required("Campo requerido"),
          description: Yup.string().nullable()
        })
      ),
      conditioned: Yup.number()
        .typeError("Campo debe ser numérico")
        .required("Campo requerido"),
      installations: Yup.number()
        .typeError("Campo debe ser numérico")
        .required("Campo requerido"),
      furniture: Yup.number()
        .typeError("Campo debe ser numérico")
        .required("Campo requerido")
      //available_equipment: Yup.array().of(Yup.string())
    })
  )
})

export const ValidationSchemaImage = Yup.object().shape({
  name: Yup.string().nullable(),
  image: Yup.string().required("Campo requerido"),
  description: Yup.string().nullable()
})

export const ValidationSchemaImages = Yup.object({
  images: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable(),
      image: Yup.string().required("Campo requerido"),
      description: Yup.string().nullable()
    })
  )
})