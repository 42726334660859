import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false)
  const [globalUser, setGlobalUser] = useState({})
  const [globalToken, setGlobalToken] = useState(false)
  const [titlePage, setTitlePage] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('mstn')
    if(token !== null) {
      setIsAuth(true)
      setGlobalToken(token)
    }
  }, [])

  const value = {
    isAuth,
    activateAuth: () => {
      setIsAuth(true)
    },
    logOut: () => {
      setIsAuth(false)
      setGlobalUser(false)
      setGlobalToken(false)
      localStorage.clear()
      window.location.reload()
    },
    globalUser,
    loadUser: (data) => {
      setGlobalUser(data)
      localStorage.setItem('msus', data);
    },
    globalToken,
    loadToken: (data) => {
      setGlobalToken(data)
      localStorage.setItem('mstn', data)
    },
    titlePage, 
    setTitlePage
  }

  return <AuthContext.Provider value={ value }>
          { children }
        </AuthContext.Provider>
}