import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaWeb } from "../services/SchemaValidation"
import { useMutation } from "react-query";
import { editPropertyWeb } from "../../../services/api/Property";
import { CircularProgress, IconButton } from "@mui/material";
import FullwidthDialog from '../../../components/Dialog/FullwidthDialog'
import { Save as SaveIcon } from '@mui/icons-material';
import Texts from "./Texts";
import Circles from "./Circles";

export default function Web({ open, setOpen, property, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({})
  //Property web
  let { handleSubmit, register, formState: { errors }, control, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaWeb),
    defaultValues: { 'texts': [], 'circles': [] } 
  })
  const { fields: fieldsTXT, append: appendTXT, remove: removeTXT, update: updateTXT } = useFieldArray({ 
    name: 'texts', control 
  })
  const { fields: fieldsCIR, append: appendCIR, remove: removeCIR, update: updateCIR } = useFieldArray({ 
    name: 'circles', control 
  })

  useEffect(() => { 
    if(open) { 
      setData(property)
      if (property.web !== undefined && property.web !== null) {
        setValue("texts", property.web.texts)
        setValue("circles", property.web.circles)
      }
    } 
  }, [open])

  const mutation = useMutation(editPropertyWeb, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction('web', false, { message: 'Error al editar los datos'}, false)
      } else { 
        handleClose()
        reportAction('web', false, { }, true) 
      }
    }
  })

  const onSubmit = (params) => {
    console.log("Params:", params)
    setIsLoading(true)
    mutation.mutate({id: property._id, params: params})
  }

  const handleClose = () => { setValue('texts', []); setValue('circles', []); setData({}); setOpen(false) }

  return (<>
    <FullwidthDialog open={open} changeOpen={handleClose} title="Editar datos web" actions={<>
      { !isLoading ? <>
      <IconButton autoFocus color="inherit" onClick={handleSubmit(onSubmit)} aria-label="save"> <SaveIcon /> </IconButton> </> : <CircularProgress /> }
    </>}>
      <Texts isLoading={isLoading} fields={fieldsTXT} updateArray={updateTXT} appendArray={appendTXT} removeArray={removeTXT} />
      <Circles isLoading={isLoading} fields={fieldsCIR} updateArray={updateCIR} appendArray={appendCIR} removeArray={removeCIR} />
    </FullwidthDialog>
  </>)
}