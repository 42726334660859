import { API_BASE_URL } from "./BaseUrl";

export const login = (params) =>
  fetch(
    `${API_BASE_URL}/auth/login`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(params)
    }
  )
  .then(res => res.json())