import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaImageLogo } from "../services/SchemaValidation"
import { useMutation } from "react-query";
import { editPropertyImageLogos } from "../../../services/api/Property";
import { CircularProgress, Grid, IconButton, InputLabel, TextField } from "@mui/material";
import { ImageSearch as ImageSearchIcon } from '@mui/icons-material'
import FullwidthDialog from '../../../components/Dialog/FullwidthDialog'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import MediaModal from '../../Media/MediaModal'
import styles from '../styles.module.css'
import ExtraLogoCard from "../../../components/Card/ExtraLogoCard";
import AddEditForm from "./AddEditForm";

export default function ImagesLogos({ open, setOpen, property, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({})
  const [openMediaModal, setOpenMediaModal] = useState(false)
  const [inputMediaSelected, setInputMediaSelected] = useState(false)
  const [mode, setMode] = useState(true)
  const [editPosition, setEditPosition] = useState(null)
  const [addEditFormSetValue, setAddEditFormSetValue] = useState(null)
  //Property image, logo and extra logos
  let { handleSubmit, register, formState: { errors }, control, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaImageLogo),
    defaultValues: { 'extra_logos': [] } 
  })
  const { fields: fieldsEL, append: appendEL, remove: removeEL, update: updateEL } = useFieldArray({ 
    name: 'extra_logos', control 
  })

  useEffect(() => { 
    if(open) { 
      setData(property)
      const keys = ["property_photo", "logo", "extra_logos"]
      for (let index = 0; index < keys.length; index++) { setValue(keys[index], property[keys[index]]) }
    } 
  }, [open])

  const mutation = useMutation(editPropertyImageLogos, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction('imageLogos', false, { message: 'Error al editar los datos'}, false)
      } else { 
        handleClose()
        reportAction('imageLogos', false, { }, true) 
      }
    }
  })

  const onSubmit = (params) => {
    console.log("Params:", params)
    setIsLoading(true)
    mutation.mutate({id: property._id, params: params})
  }

  const handleClose = () => { handleResetAddEditForm(); setValue('images', []); setData({}); setOpen(false) }

  const handleOnChangeData = (event) => { setData({...data, [event.target.name]: event.target.value})  }

  const handleOpenMedia = (input) => { setInputMediaSelected(input); setOpenMediaModal(true) }

  const handleMediaSelected = (imgUrl) => {
    if(inputMediaSelected === "addlogo") {
      setAddEditFormSetValue([{ reference: "image", value: imgUrl }])
    } else {
      setData({...data, [inputMediaSelected]: imgUrl})
      setValue(inputMediaSelected, imgUrl) 
    }
  }

  const handleRemoveExtraLogo = (position) => { removeEL(position) }

  const handleEditExtraLogo = (position) => { 
    setMode(false)
    setAddEditFormSetValue([
      { reference: "image", value: fieldsEL[position].image },
      { reference: "identifier", value: fieldsEL[position].identifier },
    ]) 
    setEditPosition(position)
  }

  const handleResetAddEditForm = () => { setAddEditFormSetValue([{ ref: "reset", value: "" }]); setMode(true) }

  const Actions = ({ index }) => (<>
    <IconButton aria-label="edit" onClick={ () => handleEditExtraLogo(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveExtraLogo(index) }> <DeleteIcon color='error'/> </IconButton>
  </>)

  return (<>
    <FullwidthDialog open={open} changeOpen={handleClose} title="Editar imagen / logos" actions={<>
      { !isLoading ? <>
      <IconButton autoFocus color="inherit" onClick={handleSubmit(onSubmit)} aria-label="save"> <SaveIcon /> </IconButton> </> : <CircularProgress /> }
    </>}>
      <div className={styles.imagelogos_container}>
        <div>
          <div className={styles.imagelogos_preview_image_input}>
            <img alt="property-views" src={data.property_photo} style={{ width: "100%", maxWidth: "150px" }}/>
            <div>
              <InputLabel htmlFor='property_photo' required style={{ fontSize: 14 }}>Imagen de la propiedad</InputLabel>
              <TextField id='property_photo' fullWidth margin="dense" type="text" name='property_photo'
                value={data.property_photo || ""}
                {...register(`property_photo`)} 
                error={errors.property_photo ? true: false}
                onChange={handleOnChangeData} />
              <ValidateErrorMessage message={errors.property_photo?.message} />
              <IconButton onClick={() => handleOpenMedia("property_photo")}> <ImageSearchIcon color="info" /> </IconButton><br />
              </div>
          </div>
          <div className={styles.imagelogos_preview_image_input}>
            <img alt="logo-views" src={data.logo} style={{ width: "100%", maxWidth: "250px" }}/>
            <div>
              <InputLabel htmlFor='logo' required style={{ fontSize: 14 }}>Logotipo principal</InputLabel>
              <TextField id='logo' fullWidth margin="dense" type="text" name='logo'
                value={data.logo || ""}
                {...register(`logo`)} 
                error={errors.logo ? true: false}
                onChange={handleOnChangeData} />
              <ValidateErrorMessage message={errors.logo?.message} />
              <IconButton onClick={() => handleOpenMedia("logo")}> <ImageSearchIcon color="info" /> </IconButton><br />
            </div>
          </div>
          <h4>Logos Extra</h4>
          { (fieldsEL.length > 0) ? (
          fieldsEL.map((item, i) => (
            <Grid item xs={12}>
              <ExtraLogoCard key={i} image={item.image} identifier={item.identifier} actions={<Actions index={i}/>}/>
              {item.name}
            </Grid>
          )) ) : <Grid item xs={12}> No hay imagenes agregadas </Grid> }
        </div>
        <div>
          <AddEditForm isLoading={isLoading} editPosition={editPosition} mode={mode} setMode={setMode} handleOpenMedia={handleOpenMedia} updateArray={updateEL} appendArray={appendEL} setValues={addEditFormSetValue}/>
        </div>
      </div>
    </FullwidthDialog>
    <MediaModal open={openMediaModal} setOpen={setOpenMediaModal} mediaSelected={handleMediaSelected} path={`${property._id}/imagelogos`} />
  </>)
}