import { useContext, useEffect, useState } from 'react'
import { CircularProgress, Fab, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip } from '@mui/material'
import { useQuery, useQueryClient } from 'react-query'
import { getProperties } from '../../services/api/Property'
import { getTowersBuildingsByProperty } from '../../services/api/TowerBuilding'
import TypeOne from '../../components/Card/TypeOne'
import styles from './styles.module.css'
import { AuthContext } from '../../context/AuthContext'
import { SnackbarGlobal } from '../../components/Snackbar'
import Add from './complementary/Add'
import Edit from './complementary/Edit'
import InteractiveMap from './complementary/InteractiveMap'
import { Edit as EditIcon, Add as AddIcon, Layers as LayersIcon  } from '@mui/icons-material'

export default function TowerBuilding() {
  const { setTitlePage } = useContext(AuthContext)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openInteractiveMap, setOpenInteractiveMap] = useState(false)
  const [propertySelected, setPropertySelected] = useState(null)
  const [propertyId, setPropertyId] = useState(null)
  const [towerBuildingSelected, setTowerBuildingSelected] = useState(null)
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [authError, setAuthError] = useState(false);
  const queryClient = useQueryClient()
  const { isLoading: plIsLoading, isFetching: plIsFetching, error: plError, data: properties } = useQuery(
    'propertiesList',
    getProperties,
    { refetchOnWindowFocus: false }
  )
  const { isLoading: tbLoading, isFetching: tbFetching, error: tbError, data: towersBuildings } = useQuery(
    ['towersBuildingsList', propertyId], 
    () => getTowersBuildingsByProperty(propertyId), 
    { refetchOnWindowFocus: false,  enabled: (propertyId !== null && propertyId !== undefined && propertyId !== "") }
  )

  useEffect(() => { setTitlePage("Torres / Edificios") }, [])

  const handleRefresData = () => { queryClient.invalidateQueries('towersBuildingsList') }

  const handleResetActions = (action) => {
    if(action === "add") setOpenAdd(false)
    if(action === "edit") setOpenEdit(false)
    if(action === "interactive_map") setOpenInteractiveMap(false)
  }

  const handleWatchAction = (action, cancel, error, success) => {
    if(cancel) return;
    if(error.message) {
      setMessageSnackbar(error.message);
      setAuthError(true);
      return;
    }
    if(success) {
      alert("Acción realizada correctamente")
      handleRefresData()
      handleResetActions(action)
      return;
    }
  }

  if(plIsLoading || plIsFetching) return <div>Cargando...</div>

  if(plError) return <div> Ha ocurrido un error </div>

  const Actions = ({ towerBuilding }) => (<>
    <Tooltip title="Editar propiedad">
      <IconButton onClick={() => { setOpenEdit(true); setTowerBuildingSelected(towerBuilding) }}> <EditIcon color="info"/> </IconButton>
    </Tooltip>
    <Tooltip title="Mapa interactivo">
      <IconButton onClick={() => { setOpenInteractiveMap(true); setTowerBuildingSelected(towerBuilding) }}> <LayersIcon color="success"/> </IconButton>
    </Tooltip>
  </>)

  return (<>
    <div className={styles.container_property_selector}>
      <Paper style={{padding: 24, borderRadius: 16}}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-property-label">Propiedades</InputLabel>
          <Select labelId="select-property-label" value={propertyId} label="Propiedad" required inputProps={{ name: 'property', id: 'select-property' }}>
            <MenuItem value="" onClick={() => setPropertyId("")}>Seleccionar</MenuItem>
            { properties.data.map((property, index) => (
              <MenuItem key={index} value={property._id} onClick={() => { setPropertyId(property._id); setPropertySelected(property); }}>{property.property_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </div>
    { (tbLoading || tbFetching) ? <div className={styles.container_cards}><CircularProgress /></div> :
    (towersBuildings !== undefined) &&
    <div className={styles.container_cards}>
      { towersBuildings.data.map((towerbuilding, index) => 
        <TypeOne actions={<Actions towerBuilding={towerbuilding}/>} key={index} name={towerbuilding.name} logo={propertySelected.logo} image={propertySelected.property_photo} />
      )}
    </div>
    }
    <span className={styles.fab_add}>
      <Tooltip title="Agregar"><Fab color="primary" onClick={() => setOpenAdd(true)}><AddIcon /></Fab></Tooltip>
    </span>
    <Add open={openAdd} setOpen={setOpenAdd} propertyId={propertyId} reportAction={handleWatchAction}/>
    <Edit open={openEdit} setOpen={setOpenEdit} towerBuilding={towerBuildingSelected} reportAction={handleWatchAction}/>
    <InteractiveMap open={openInteractiveMap} setOpen={setOpenInteractiveMap} towerBuilding={towerBuildingSelected} reportAction={handleWatchAction}/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
  </>)
}