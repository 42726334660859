import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext"
import AmenityCard from "../../components/Card/AmenityCard";
import { useQuery, useQueryClient } from "react-query"
import { getTenants } from "../../services/api/Tenant"
import styles from "./styles.module.css"
import AddIcon from "@mui/icons-material/Add"
import Add from './complementary/Add'
import Edit from './complementary/Edit'
import Delete from './complementary/Delete'
import { SnackbarGlobal } from "../../components/Snackbar";
import { Fab, Tooltip } from "@mui/material";
import ToolBar from "../../components/ToolBar";

export default function Tenants() {
  const { setTitlePage } = useContext(AuthContext)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [tenantSelected, setTenantSelected] = useState({})
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [authError, setAuthError] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const queryClient = useQueryClient()
  const { isLoading, isFetching, error, data } = useQuery(
    'tenantsList', 
    getTenants,
    { refetchOnWindowFocus: false }
  )

  useEffect(() => { setTitlePage("Inquilinos") }, [])

  useEffect(() => { 
    if(data !== undefined && data !== null && data !== "")
      setDataFilter(data.data) 
  }, [data])

  const handleRefresData = () => { queryClient.invalidateQueries('tenantsList') }

  const handleResetActions = (action) => {
    if(action === "add") setOpenAdd(false)
    if(action === "edit") setOpenEdit(false)
    if(action === "delete") setOpenDelete(false)
  }

  const handleWatchAction = (action, cancel, error, success) => {
    if(cancel) return;
    if(error.message) {
      setMessageSnackbar(error.message);
      setAuthError(true);
      return;
    }
    if(success) {
      alert("Acción realizada correctamente")
      handleRefresData()
      handleResetActions(action)
      return;
    }
  }

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  if(isLoading || isFetching) return <div>Cargando...</div>
  if(error) return <div className={styles.box_container}> Ha ocurrido un error </div>

  return (<>
    <div className={ styles.toolbar_container }>
      <ToolBar searchFunction={handleSearch} />
    </div>
    <div className={styles.box_container}>
      { dataFilter !== null &&
      dataFilter.map((item) => 
        <AmenityCard key={item._id} 
          name={item.name} 
          description={item.description} 
          image={item.logo || "/images/picture_empty.png"} 
          actionEdit={() => { setOpenEdit(true); setTenantSelected(item) }}
          actionDelete={() => { setOpenDelete(true); setTenantSelected(item) }}
        />
      )}
    </div>
    <span className={styles.fab_add}>
      <Tooltip title="Agregar"><Fab color="primary" onClick={() => setOpenAdd(true)}><AddIcon /></Fab></Tooltip>
    </span>    
    <Add open={openAdd} setOpen={setOpenAdd} tenant={tenantSelected} reportAction={handleWatchAction}/>
    <Edit open={openEdit} setOpen={setOpenEdit} tenant={tenantSelected} reportAction={handleWatchAction}/>
    <Delete open={openDelete} setOpen={setOpenDelete} tenant={tenantSelected} reportAction={handleWatchAction}/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
  </>)
}