import { /*useEffect,*/ useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaWebCircle } from "../services/SchemaValidation"
import { Button, InputLabel, TextField, IconButton, Typography, Grid } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";
import styles from '../styles.module.css'
import SimpleDataCard from "../../../components/Card/SimpleDataCard";

export default function Circles({ isLoading, fields, updateArray, appendArray, removeArray }) {
  let schemaWebCircle = { text: "", value: "" }
  const [editPositionWebCircle, setEditPositionWebCircle] = useState(null)
  const [modeWebCircle, setModeWebCircle] = useState(true)
  const [instanceWebCircle, setInstanceWebCircle] = useState(schemaWebCircle)
  //WebCircle
  let { reset: resetOneText, handleSubmit: handleSubmitOneText, register: registerOneText, formState: { errors: errorsOneText }, setValue: setValueOneText} = useForm({ resolver: yupResolver(ValidationSchemaWebCircle) })

  const handleChangeData = (event) => { 
    setInstanceWebCircle({...instanceWebCircle, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditWebCircle = () => { setInstanceWebCircle({}); resetOneText(schemaWebCircle); setModeWebCircle(true) }

  const handleSaveEdit = () => {
    updateArray(editPositionWebCircle, instanceWebCircle)
    setInstanceWebCircle({}); resetOneText(schemaWebCircle); setModeWebCircle(true)
  }

  const onAddWebCircle = (params) => {
    appendArray(params)
    resetOneText(schemaWebCircle)
    setInstanceWebCircle(schemaWebCircle)
  }

  const handleRemoveWebCircle = (position) => { removeArray(position) }

  const handleEditWebCircle = (position) => { 
    setModeWebCircle(false)
    setValueOneText("text", fields[position].text)
    setValueOneText("value", fields[position].value)
    setInstanceWebCircle({ text: fields[position].text, value: fields[position].value })
    setEditPositionWebCircle(position)
  }

  const Actions = ({ index }) => (<>
    <IconButton aria-label="edit" onClick={ () => handleEditWebCircle(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveWebCircle(index) }> <DeleteIcon color='error'/> </IconButton>
  </>)

  return (<>
  <div className={styles.imagelogos_container}>
    <div>
      <h4>Círculos</h4>
      { (fields.length > 0) ? (
      fields.map((item, i) => (
        <Grid item xs={12}>
          <SimpleDataCard key={i} title={item.value} subtitle={item.text} actions={<Actions index={i}/>}/><br />
        </Grid>
      )) ) : <Grid item xs={12}> No hay círculos agregados </Grid> }
    </div>
    <div>
      <Typography variant="inherit">{ modeWebCircle ? "Agregar" : "Editar" } círculo</Typography><br />
      <InputLabel htmlFor='text' required style={{ fontSize: 14 }}>Texto</InputLabel>
      <TextField id='text' fullWidth margin="dense" type="text" name='text' multiline
        value={instanceWebCircle.text || ""}
        {...registerOneText(`text`)} 
        error={errorsOneText.text ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errorsOneText.text?.message} /><br />
      <InputLabel htmlFor='value' required style={{ fontSize: 14 }}>Valor</InputLabel>
      <TextField id='value' fullWidth margin="dense" type="text" name='value'
        value={instanceWebCircle.value || ""}
        {...registerOneText(`value`)} 
        error={errorsOneText.value ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errorsOneText.value?.message} />
      {!isLoading ? <div style={{ textAlign: "right" }}>
        { (!modeWebCircle) ? <>
          <Button onClick={handleCancelEditWebCircle} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
          <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
        </> :  <Button onClick={handleSubmitOneText(onAddWebCircle)} variant="contained" color="primary"> Agregar </Button> }
      </div> : <></> }
    </div>
  </div>
  </>)
}