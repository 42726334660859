import { Dashboard as DashboardIcon, Language as LanguageIcon, BusinessCenter as BusinessCenterIcon, CorporateFare as CorporateFareIcon, Apartment as ApartmentIcon, Layers as LayersIcon } from '@mui/icons-material';

export const MenuItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    to: "/admin"
  },
  /*{
    text: "Amenidades",
    icon: <ArrowForwardIosIcon />,
    to: "/admin/amenidades"
  },
  {
    text: "Movilidad",
    icon: <ArrowForwardIosIcon />,
    to: "/admin/movilidades"
  },
  {
    text: "Instalaciones",
    icon: <ArrowForwardIosIcon />,
    to: "/admin/instalaciones"
  },*/
  {
    text: "Páginas Web",
    icon: <LanguageIcon />,
    to: "/admin/paginas-web"
  },
  {
    text: "Inquilinos",
    icon: <BusinessCenterIcon />,
    to: "/admin/inquilinos"
  },
  {
    text: "Propiedades",
    icon: <CorporateFareIcon />,
    to: "/admin/propiedades"
  },
  {
    text: "Torres / Edificios",
    icon: <ApartmentIcon />,
    to: "/admin/torres-edificios"
  },
  {
    text: "Pisos",
    icon: <LayersIcon />,
    to: "/admin/pisos"
  }
]