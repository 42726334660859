import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaExtraLogo } from "../services/SchemaValidation"
import { Button, IconButton, InputLabel, TextField, Typography  } from "@mui/material";
import { ImageSearch as ImageSearchIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";

export default function AddEditForm({ isLoading, editPosition, mode, setMode, handleOpenMedia, updateArray, appendArray, setValues }) {
  let schemaExtraLogo = { image: "", identifier: "" }
  const [instanceExtraLogo, setInstanceExtraLogo] = useState(schemaExtraLogo)
  //ExtraLogo
  let { reset: resetOne, handleSubmit: handleSubmitOne, register: registerOne, formState: { errors: errorsOne }, setValue: setValueOne} = useForm({ resolver: yupResolver(ValidationSchemaExtraLogo) })

  useEffect(() => {
    if(setValues !==  null) {
      let instanceTemp = {...instanceExtraLogo}
      setValues.forEach(data => {
        if(data.reference === "reset") {
          resetOne(schemaExtraLogo);
        } else {
          setValueOne(data.reference, data.value)
          instanceTemp[data.reference] = data.value
        }
      })
      setInstanceExtraLogo(instanceTemp)
    }
  }, [setValues]) 
  
  const handleChangeData = (event) => { 
    setInstanceExtraLogo({...instanceExtraLogo, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditExtraLogo = () => { setInstanceExtraLogo({}); resetOne(schemaExtraLogo); setMode(true) }

  const handleSaveEdit = () => {
    updateArray(editPosition, instanceExtraLogo)
    setInstanceExtraLogo({}); resetOne(schemaExtraLogo); setMode(true)
  }

  const onAddExtraLogo = (params) => {
    appendArray(params)
    resetOne(schemaExtraLogo)
    setInstanceExtraLogo(schemaExtraLogo)
  }

  return (<>
    <Typography variant="inherit">{ mode ? "Agregar" : "Editar" } extra logo</Typography><br />
    { (instanceExtraLogo.image !== undefined && instanceExtraLogo.image !== null && instanceExtraLogo.image !== "") && <div style={{ textAlign: "center" }}>
      <img alt="preview-url" src={instanceExtraLogo.image} style={{ width: "100%", maxWidth: "250px" }}/>
    </div> }
    <InputLabel htmlFor='image' required style={{ fontSize: 14 }}>Logo</InputLabel>
    <TextField id='image' fullWidth margin="dense" type="text" name='image'
      value={instanceExtraLogo.image || ""}
      {...registerOne(`image`)} 
      error={errorsOne.image ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.image?.message} />
    <IconButton onClick={() => handleOpenMedia("addlogo")}> <ImageSearchIcon color="info" /> </IconButton><br />
    <InputLabel htmlFor='identifier' required style={{ fontSize: 14 }}>Identificador</InputLabel>
    <TextField id='identifier' fullWidth margin="dense" type="text" name='identifier'
      value={instanceExtraLogo.identifier || ""}
      {...registerOne(`identifier`)} 
      error={errorsOne.identifier ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.identifier?.message} />
    {!isLoading ? <div style={{ textAlign: "right" }}>
      { (!mode) ? <>
        <Button onClick={handleCancelEditExtraLogo} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
        <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
      </> :  <Button onClick={handleSubmitOne(onAddExtraLogo)} variant="contained" color="primary"> Agregar </Button> }
    </div> : <></> }      
  </>)
}