import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      //light: '#616161',
      main: '#7D868D',
      //dark: '#212121',
      //contrastText: '#fff',
    },
    secondary: {
      //light: '#e0e0e0',
      main: '#FBB15C',
      //dark: '#9e9e9e',
      //contrastText: '#000',
    },
    error: {
      main : '#D0021B',
      contrastText: '#fff'
    },
    warning: {
      main : '#ff9800',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main : '#2196f3',
      contrastText: '#fff'
    },
    success: {
      main : '#4caf50',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
})

export default function ThemeMUIProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  )
}