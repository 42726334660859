import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext"
import TypeOne from "../../components/Card/TypeOne";
import { useQuery, useQueryClient } from "react-query"
import { getWebPages } from "../../services/api/WebPage"
import styles from "./styles.module.css"
import AddIcon from "@mui/icons-material/Add"
import Add from './complementary/Add'
import Edit from './complementary/Edit'
import Delete from './complementary/Delete'
import Codes from './complementary/Codes'
import SocialLinks from './SocialLinks'
import { SnackbarGlobal } from "../../components/Snackbar";
import { Fab, IconButton, Tooltip } from "@mui/material";
import ToolBar from "../../components/ToolBar";
import { Edit as EditIcon, Delete as DeleteIcon, Code as CodeIcon, Link as LinkIcon } from "@mui/icons-material";

export default function WebPage() {
  const { setTitlePage } = useContext(AuthContext)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openCodes, setOpenCodes] = useState(false)
  const [openSocialLinks, setOpenSocialLinks] = useState(false)
  const [webPageSelected, setWebPageSelected] = useState({})
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [authError, setAuthError] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const queryClient = useQueryClient()
  const { isLoading, isFetching, error, data } = useQuery(
    'webPagesList', 
    getWebPages,
    { refetchOnWindowFocus: false }
  )

  useEffect(() => { setTitlePage("Sitios Web") }, [])

  useEffect(() => { 
    if(data !== undefined && data !== null && data !== "")
      setDataFilter(data.data) 
  }, [data])

  const handleRefresData = () => { queryClient.invalidateQueries('webPagesList') }

  const handleResetActions = (action) => {
    if(action === "add") setOpenAdd(false)
    if(action === "edit") setOpenEdit(false)
    if(action === "delete") setOpenDelete(false)
    if(action === "codes") setOpenCodes(false)
    if(action === "social_links") setOpenSocialLinks(false)
  }

  const handleWatchAction = (action, cancel, error, success) => {
    if(cancel) return;
    if(error.message) {
      setMessageSnackbar(error.message);
      setAuthError(true);
      return;
    }
    if(success) {
      alert("Acción realizada correctamente")
      handleRefresData()
      handleResetActions(action)
      return;
    }
  }

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }
  
  const Actions = ({ webpage }) => (<>
    <IconButton onClick={() => { setWebPageSelected(webpage); setOpenEdit(true) }}> <EditIcon color="info"/> </IconButton>
    <IconButton onClick={() => { setWebPageSelected(webpage); setOpenDelete(true) }}> <DeleteIcon color="error"/> </IconButton>
    <IconButton onClick={() => { setWebPageSelected(webpage); setOpenCodes(true) }}> <CodeIcon color="success"/> </IconButton>
    <IconButton onClick={() => { setWebPageSelected(webpage); setOpenSocialLinks(true) }}> <LinkIcon color="success"/> </IconButton>
  </>)

  if(isLoading || isFetching) return <div>Cargando...</div>
  if(error) return <div className={styles.box_container}> Ha ocurrido un error </div>

  return (<>
    <div className={ styles.toolbar_container }>
      <ToolBar searchFunction={handleSearch} />
    </div>
    <div className={styles.box_container}>
      { dataFilter !== null &&
      dataFilter.map((item) => <>
        <TypeOne key={item._id} actions={<Actions webpage={item}/>}
          name={item.title} 
          logo={item.header_logo !== "" ? item.header_logo : '/images/logo_gicsa_bw.png'} 
          image={'/images/Imagen-Website.jpg'} />
      </>)}
    </div>
    <span className={styles.fab_add}>
      <Tooltip title="Agregar"><Fab color="primary" onClick={() => setOpenAdd(true)}><AddIcon /></Fab></Tooltip>
    </span>
    <Add open={openAdd} setOpen={setOpenAdd} reportAction={handleWatchAction}/>
    <Edit open={openEdit} setOpen={setOpenEdit} webPage={webPageSelected} reportAction={handleWatchAction}/>
    <Delete open={openDelete} setOpen={setOpenDelete} webPage={webPageSelected} reportAction={handleWatchAction}/>
    <Codes open={openCodes} setOpen={setOpenCodes} webPage={webPageSelected} reportAction={handleWatchAction}/>
    <SocialLinks open={openSocialLinks} setOpen={setOpenSocialLinks} webPage={webPageSelected} reportAction={handleWatchAction}/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
  </>)
}