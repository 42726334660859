import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaTenants } from "../services/SchemaValidation"
import { useMutation } from "react-query";
import { useQuery } from "react-query"
import { getTenants } from "../../../services/api/Tenant"
import { editPropertyTenants } from "../../../services/api/Property";
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import DialogC from '../../../components/Dialog/index'

export default function Tenants({ open, setOpen, property, reportAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({})
  const [valueT, setValueT] = useState([]);
  let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ 
    resolver: yupResolver(ValidationSchemaTenants)
  })
  const { isLoading: tLoading, isFetching: tFetching, refetch: tRefetch, error: tError, data: tenants } = useQuery(
    'tenantsList', 
    getTenants, 
    { refetchOnWindowFocus: false, enabled: false } 
  )

  useEffect(() => { 
    if(open) { 
      setData(property)
      setValue("tenants", property.tenants)
      //const keys = ["property_name", "tradename", "slug", "total_rent_square_meters", "towers_buildings_number", "parking_spaces"]
      //for (let index = 0; index < keys.length; index++) { setValue(keys[index], property[keys[index]]) }
      tRefetch()
    } 
  }, [open])

  const mutation = useMutation(editPropertyTenants, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('tenants', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('tenants', false, { }, true) }
    }
  })

  const onSubmit = (params) => { 
    params.tenants = params.tenants.filter(t => t !== "false")
    console.log("Params:", params.tenants)
    setIsLoading(true)
    mutation.mutate({id: property._id, params: params.tenants})
  }

  useEffect(() => {
    if(tenants !== undefined && tenants !== null) {
      let tempObject = []
      tenants.data.forEach(element => {
        let found = property.tenants.find(tenant => tenant === element._id)
        found === undefined ? element.selected = false : element.selected = true
        tempObject.push(element)
      });      
      setValueT(tempObject)
    }
  }, [tenants])

  const handleChangeT = (event) => {
    let findIndex = valueT.findIndex(element => element._id === event.target.value)
    let newArr = [...valueT]
    newArr[findIndex].selected ? newArr[findIndex].selected = false : newArr[findIndex].selected = true
    setValueT(newArr)
  }

  const handleClose = () => { reset(); setData({}); setValueT([]); setOpen(false) }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(onSubmit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  if(tLoading || tFetching) return <div>Cargando...</div>
  if(tError) return <div> Ha ocurrido un error </div>

  return (
    <DialogC open={open} title={`Editar inquilinos`} actions={<Actions />}>
      <FormControl>
        <FormLabel style={{ fontSize: 14 }}>Lista de Inquilinos</FormLabel>
        <FormGroup aria-label="position" onChange={handleChangeT}>
          { valueT.map((tenant, index) => (
            <FormControlLabel key={`${index}-${tenant._id}`} control={
              <Checkbox value={tenant._id} checked={tenant.selected} name="tenant" {...register(`tenants.${index}`)} />
            } label={tenant.name} labelPlacement="end" />
          ))}
        </FormGroup>
      </FormControl>
      <ValidateErrorMessage message={errors.tenants?.message} /><br />
    </DialogC>
  )
}