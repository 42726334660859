import { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, InputLabel, TextField } from "@mui/material";
import DialogC from "../../../components/Dialog";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useMutation } from 'react-query'
import { editWebPage } from "../../../services/api/WebPage";
import MediaModal from '../../Media/MediaModal'
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html';

export default function Edit({ open, setOpen, webPage, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataWebPage, setDataWebPage] = useState({})
  const [inputMediaSelected, setInputMediaSelected] = useState(false)
  const [openMediaModal, setOpenMediaModal] = useState(false)
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  useEffect(() => {
    if(open) {
      setDataWebPage(webPage)
      const keys = ["header_logo", "title", "slogan", "phone","email", "footer_logo", "footer_data", "footer_all_rights_reserved"]
      for (let index = 0; index < keys.length; index++) {
        setValue(keys[index], webPage[keys[index]])
      }
    }
  }, [open])

  const mutation = useMutation(editWebPage, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('edit', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('edit', false, { }, true) }
    }
  })

  const handleEdit = (params) => { 
    console.log("prueba", params)
    console.log("id", dataWebPage._id)
    setIsLoading(true)
    mutation.mutate({id: dataWebPage._id, params: params})
  }

  const handleClose = () => { reset(); setDataWebPage({}); setOpen(false) }

  const handleOnChangeData = (event) => { 
    setDataWebPage({...dataWebPage, [event.target.name]: event.target.value}) 
  }

  const handleMediaSelected = (imgUrl) => {
    /*setDataWebPage({...dataWebPage, logo: imgUrl})
    setValue("logo", imgUrl) */
    setDataWebPage({...dataWebPage, [inputMediaSelected]: imgUrl})
    setValue(inputMediaSelected, imgUrl)
  }

  const handleOpenMedia = (input) => { setInputMediaSelected(input); setOpenMediaModal(true) }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)
  //https://cdn-icons-png.flaticon.com/512/6133/6133429.png
  //https://cloudinary.com/pricing
  return (<>
    <DialogC open={open} title={`Editar - ${webPage.title}`} actions={<Actions />}>
      <>
      <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataWebPage.header_logo || "/images/picture_empty.png"} style={{ width: "90%" }}/>
          </div>
          <div>
          <InputLabel htmlFor='header_logo' required style={{ fontSize: 14 }}>Header logo</InputLabel>
          <TextField id='header_logo' fullWidth margin="dense" type="text" name='header_logo'
            value={dataWebPage.header_logo || ""}
            {...register(`header_logo`)} 
            error={errors.header_logo ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.header_logo?.message} />
          <IconButton onClick={() => handleOpenMedia("header_logo")}> <ImageSearchIcon color="info" /> </IconButton>
          </div>
        </div>
        <br />
        <InputLabel htmlFor='title' required style={{ fontSize: 14 }}>Titulo</InputLabel>
        <TextField id='title' fullWidth margin="dense" type="text" name='title'
          value={dataWebPage.title || ""}
          {...register(`title`)} 
          error={errors.title ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.title?.message} />
        <br />
        <InputLabel htmlFor='slogan' style={{ fontSize: 14 }}>Slogan</InputLabel>
        <TextField id='slogan' fullWidth margin="dense" type="text" name='slogan'
          value={dataWebPage.slogan || ""}
          {...register(`slogan`)} 
          error={errors.slogan ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.slogan?.message} />
        <br />
        <InputLabel htmlFor='phone' required style={{ fontSize: 14 }}>Teléfono</InputLabel>
        <TextField id='phone' fullWidth margin="dense" type="number" name='phone'
          value={dataWebPage.phone || ""}
          {...register(`phone`)} 
          error={errors.phone ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.phone?.message} />
        <br />
        <InputLabel htmlFor='email' required style={{ fontSize: 14 }}>Correo electrónico</InputLabel>
        <TextField id='email' fullWidth margin="dense" type="text" name='email'
          value={dataWebPage.email || ""}
          {...register(`email`)} 
          error={errors.email ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.email?.message} />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataWebPage.header_logo || "/images/picture_empty.png"} style={{ width: "90%" }}/>
          </div>
          <div>
          <InputLabel htmlFor='footer_logo' required style={{ fontSize: 14 }}>Footer logo</InputLabel>
          <TextField id='footer_logo' fullWidth margin="dense" type="text" name='footer_logo'
            value={dataWebPage.footer_logo || ""}
            {...register(`footer_logo`)} 
            error={errors.footer_logo ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.footer_logo?.message} />
          <IconButton onClick={() => handleOpenMedia("footer_logo")}> <ImageSearchIcon color="info" /> </IconButton>
          </div>
        </div>
        <br />
        <InputLabel htmlFor='footer_data' required style={{ fontSize: 14 }}>Footer data</InputLabel>
        <CodeMirror style={{ fontSize: 15 }}
          placeholder={`Agrega tu código`}
          value={dataWebPage.footer_data || ""}
          height="300px"
          theme='dark'
          extensions={[html()]}
          onChange={(value, viewUpdate) => {
            console.log('value:', value);
            setDataWebPage({...dataWebPage, footer_data: value}) 
            setValue("footer_data", value)
          }}
        />
        <ValidateErrorMessage message={errors.footer_data?.message} />
        <br />
        <InputLabel htmlFor='footer_all_rights_reserved' required style={{ fontSize: 14 }}>Derechos reservados</InputLabel>
        <TextField id='footer_all_rights_reserved' fullWidth margin="dense" type="text" name='footer_all_rights_reserved'
          value={dataWebPage.footer_all_rights_reserved || ""}
          {...register(`footer_all_rights_reserved`)} 
          error={errors.footer_all_rights_reserved ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.footer_all_rights_reserved?.message} />
      </>
    </DialogC>
    <MediaModal open={openMediaModal} setOpen={setOpenMediaModal} mediaSelected={handleMediaSelected} path="webPages" />
  </>)
}