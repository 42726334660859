import { /*useEffect,*/ useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaBasePrice } from "../services/SchemaValidation"
import { Button, InputLabel, TextField, IconButton, Typography, Grid } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";
import styles from '../styles.module.css'
import SimpleDataCard from "../../../components/Card/SimpleDataCard";

export default function BasePriceF({ isLoading, fields, updateArray, appendArray, removeArray }) {
  let schemaWebBasePrice = { starting_price: 0, cam: 0, currency: "" }
  const [editPositionWebBasePrice, setEditPositionWebBasePrice] = useState(null)
  const [modeWebBasePrice, setModeWebBasePrice] = useState(true)
  const [instanceWebBasePrice, setInstanceWebBasePrice] = useState(schemaWebBasePrice)
  //WebBasePrice
  let { reset: resetOneBasePrice, handleSubmit: handleSubmitOneBasePrice, register: registerOneBasePrice, formState: { errors: errorsOneBasePrice }, setValue: setValueOneBasePrice} = useForm({ resolver: yupResolver(ValidationSchemaBasePrice) })

  const handleChangeData = (event) => { 
    setInstanceWebBasePrice({...instanceWebBasePrice, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditWebBasePrice = () => { setInstanceWebBasePrice({}); resetOneBasePrice(schemaWebBasePrice); setModeWebBasePrice(true) }

  const handleSaveEdit = () => {
    updateArray(editPositionWebBasePrice, instanceWebBasePrice)
    setInstanceWebBasePrice({}); resetOneBasePrice(schemaWebBasePrice); setModeWebBasePrice(true)
  }

  const onAddWebBasePrice = (params) => {
    appendArray(params)
    resetOneBasePrice(schemaWebBasePrice)
    setInstanceWebBasePrice(schemaWebBasePrice)
  }

  const handleRemoveWebBasePrice = (position) => { removeArray(position) }

  const handleEditWebBasePrice = (position) => { 
    setModeWebBasePrice(false)
    setValueOneBasePrice("starting_price", fields[position].starting_price)
    setValueOneBasePrice("cam", fields[position].cam)
    setValueOneBasePrice("currency", fields[position].currency)
    setInstanceWebBasePrice({ starting_price: fields[position].starting_price, cam: fields[position].cam, currency: fields[position].currency })
    setEditPositionWebBasePrice(position)
  }

  const Actions = ({ index }) => (<>
    <IconButton aria-label="edit" onClick={ () => handleEditWebBasePrice(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton aria-label="delete" onClick={ () => handleRemoveWebBasePrice(index) }> <DeleteIcon color='error'/> </IconButton>
  </>)

  return (<>
  <div className={styles.imagelogos_container}>
    <div>
      <h4>Precios Base</h4>
      { (fields.length > 0) ? (
      fields.map((item, i) => (
        <Grid item xs={12}>
          <SimpleDataCard key={i} title={`Precio base: ${item.starting_price} ${item.currency}`} subtitle={`CAM: ${item.cam} ${item.currency}`} actions={<Actions index={i}/>}/><br />
        </Grid>
      )) ) : <Grid item xs={12}> No hay precios base agregados </Grid> }
    </div>
    <div>
      <Typography variant="inherit">{ modeWebBasePrice ? "Agregar" : "Editar" } precio base</Typography><br />
      <InputLabel htmlFor='starting_price' required style={{ fontSize: 14 }}>Precio Base</InputLabel>
      <TextField id='starting_price' fullWidth margin="dense" type="number" name='starting_price'
        value={instanceWebBasePrice.starting_price || ""}
        {...registerOneBasePrice(`starting_price`)} 
        error={errorsOneBasePrice.starting_price ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errorsOneBasePrice.starting_price?.message} /><br />
      <InputLabel htmlFor='cam' required style={{ fontSize: 14 }}>CAM</InputLabel>
      <TextField id='cam' fullWidth margin="dense" type="number" name='cam'
        value={instanceWebBasePrice.cam || ""}
        {...registerOneBasePrice(`cam`)} 
        error={errorsOneBasePrice.cam ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errorsOneBasePrice.cam?.message} /><br />
      <InputLabel htmlFor='currency' required style={{ fontSize: 14 }}>Moneda</InputLabel>
      <TextField id='currency' fullWidth margin="dense" type="text" name='currency'
        value={instanceWebBasePrice.currency || ""}
        {...registerOneBasePrice(`currency`)} 
        error={errorsOneBasePrice.currency ? true: false}
        onChange={handleChangeData} />
      <ValidateErrorMessage message={errorsOneBasePrice.currency?.message} />
      {!isLoading ? <div style={{ textAlign: "right" }}>
        { (!modeWebBasePrice) ? <>
          <Button onClick={handleCancelEditWebBasePrice} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
          <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
        </> :  <Button onClick={handleSubmitOneBasePrice(onAddWebBasePrice)} variant="contained" color="primary"> Agregar </Button> }
      </div> : <></> }
    </div>
  </div>
  </>)
}