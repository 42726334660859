import { useContext, useEffect, useState } from "react"
//import Media from "../Media"
import { AuthContext } from "../../context/AuthContext" 
import MediaModal from "../Media/MediaModal"

import CodeMirror from '@uiw/react-codemirror'
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { oneDark } from "@codemirror/theme-one-dark";
import { Select } from "./Select";
import { string } from "yup";

const langs = {
  html: html(),
  javascript : javascript(),
  json: json()
}

const themeOptions = ['dark', 'light'];

export default function Dashboard() {
  const [openMediaModal, setOpenMediaModal] = useState(false)
  const [mediaSelected, setMediaSelected] = useState("")
  const [mode, setMode] = useState('javascript')
  const [extensions, setExtensions] = useState(langs.html)
  const [theme, setTheme] = useState('dark');

  const { setTitlePage } = useContext(AuthContext)

  useEffect(() => { setTitlePage("Dashboard") })

  const handleLangChange = (value) => {
    setExtensions(langs[value])
    setMode(value)
  }

  return (<>
    <main style={{ padding: "1rem 0" }}>
      <h2>Dashboard</h2>
      {/* <CodeMirror style={{ fontSize: 15 }}
        placeholder={`Agrega tu código`}
        value=""
        height="300px"
        theme={theme}
        extensions={[extensions]}
        onChange={(value, viewUpdate) => {
          console.log('value:', value);
        }}
      />
      <Select
        label="Lenguaje"
        options={Object.keys(langs)}
        value={mode}
        onChange={(evn) => handleLangChange(evn.target.value)}
      />
      <Select
        label="Tema"
        options={themeOptions}
        value={theme}
        onChange={(evn) => setTheme(evn.target.value)}
      />
      {/*<CodeMirror style={{ fontSize: 14 }}
        placeholder={`Agrega tu código`}
        value=""
        height="300px"
        theme={`light`}
        extensions={[extensions]}
        onChange={(value, viewUpdate) => {
          console.log('value:', value);
        }}
      />*/}
      {/*<img src={mediaSelected} height="100" />
      <input type="text" value={mediaSelected} readOnly/>
      <button onClick={() => setOpenMediaModal(true)}>Abrir media modal</button> */}
    </main>
    <MediaModal open={openMediaModal} setOpen={setOpenMediaModal} mediaSelected={setMediaSelected} path="files" />
  </>)
}