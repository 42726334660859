import { useContext, useEffect, useState } from "react"
import { Fab, IconButton, Tooltip } from "@mui/material"
import { useQuery, useQueryClient } from "react-query"
import { getProperties } from "../../services/api/Property"
import { AuthContext } from "../../context/AuthContext" 
import styles from "./styles.module.css"
import TypeOne from "../../components/Card/TypeOne"
import AddIcon from "@mui/icons-material/Add"
import { Edit as EditIcon, LocationOn as LocationOnIcon, BurstMode as BurstModeIcon, PhotoCamera as PhotoCameraicon, Construction as ConstructionIcon, Storefront as StorefrontIcon, AirportShuttle as AirportShuttleIcon, BusinessCenter as BusinessCenterIcon, Language as LanguageIcon, AttachMoney as AttachMoneyIcon, Layers as LayersIcon  } from '@mui/icons-material'
import { SnackbarGlobal } from "../../components/Snackbar";
import Add from './complementary/Add'
import Edit from "./complementary/Edit"
import ImagesLogos from "./ImageLogos/index.jsx"
import EditImagesData from "./complementary/ImagesData/EditImagesData"
import EditLocationData from "./complementary/LocationData/EditLocationData"
import Amenities from "./Amenities"
import Facilities from "./Facilities"
import Mobilities from "./Mobilities"
import Tenants from "./Tenants"
import Web from "./Web"
import BasePrice from "./BasePrice"
import InteractiveMap from './InteractiveMap'
import ToolBar from "../../components/ToolBar"

export default function Properties() {
  const { setTitlePage } = useContext(AuthContext)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openImageLogos, setOpenImageLogos] = useState(false)
  const [openEditLocation, setOpenEditLocation] = useState(false)
  const [openEditImages, setOpenEditImages] = useState(false)
  const [openAmenities, setOpenAmenities] = useState(false)
  const [openFacilities, setOpenFacilities] = useState(false)
  const [openMobilities, setOpenMobilities] = useState(false)
  const [openTenants, setOpenTenants] = useState(false)
  const [openWeb, setOpenWeb] = useState(false)
  const [openBasePrice, setOpenBasePrice] = useState(false)
  const [openInteractiveMap, setOpenInteractiveMap] = useState(false)
  const [propertySelected, setPropertySelected] = useState({})
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [authError, setAuthError] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const queryClient = useQueryClient()
  const { isLoading, isFetching, error, data } = useQuery(
    'propertiesList', 
    getProperties,
    { refetchOnWindowFocus: false }
  )

  useEffect(() => { setTitlePage("Propiedades") })

  useEffect(() => { 
    if(data !== undefined && data !== null && data !== "")
      setDataFilter(data.data) 
  }, [data])

  const handleRefresData = () => { queryClient.invalidateQueries('propertiesList') }

  const handleResetActions = (action) => {
    if(action === "add") setOpenAdd(false)
    if(action === "edit") setOpenEdit(false)
    if(action === "imageLogos") setOpenImageLogos(false)
    if(action === "editLocation") setOpenEditLocation(false)
    if(action === "editImages") setOpenEditImages(false)
    if(action === "amenities") setOpenAmenities(false)
    if(action === "facilities") setOpenFacilities(false)
    if(action === "mobilities") setOpenMobilities(false)
    if(action === "tenants") setOpenTenants(false)
    if(action === "web") setOpenWeb(false)
    if(action === "basePrice") setOpenBasePrice(false)
    if(action === "interactive_map") setOpenInteractiveMap(false)
  }

  const handleWatchAction = (action, cancel, error, success) => {
    if(cancel) return;
    if(error.message) {
      setMessageSnackbar(error.message);
      setAuthError(true);
      return;
    }
    if(success) {
      alert("Acción realizada correctamente")
      handleRefresData()
      handleResetActions(action)
      return;
    }
  }

  const handleSearch = (e) => {
    const dataFilter = data.data.filter(item => item.property_name.toLowerCase().includes(e.target.value.toLowerCase(), 0))
    setDataFilter(dataFilter)
  }

  const Actions = ({ property }) => (<>
    <IconButton onClick={() => {setOpenEdit(true); setPropertySelected(property)}}> <EditIcon color="info"/> </IconButton>
    <IconButton onClick={() => { setOpenImageLogos(true); setPropertySelected(property) }}> <BurstModeIcon color="secondary"/> </IconButton>
    <IconButton onClick={() => { setOpenEditLocation(true); setPropertySelected(property) }}> <LocationOnIcon color="secondary"/> </IconButton>
    <IconButton onClick={() => { setOpenEditImages(true); setPropertySelected(property) }}> <PhotoCameraicon color="success"/> </IconButton>
    <IconButton onClick={() => { setOpenAmenities(true); setPropertySelected(property) }}> <ConstructionIcon color="success"/> </IconButton>
    <IconButton onClick={() => { setOpenFacilities(true); setPropertySelected(property) }}> <StorefrontIcon color="success"/> </IconButton>
    <IconButton onClick={() => { setOpenMobilities(true); setPropertySelected(property) }}> <AirportShuttleIcon color="success"/> </IconButton>
    <IconButton onClick={() => { setOpenTenants(true); setPropertySelected(property) }}> <BusinessCenterIcon color="success"/> </IconButton>
    <IconButton onClick={() => { setOpenWeb(true); setPropertySelected(property) }}> <LanguageIcon color="success"/> </IconButton>
    <IconButton onClick={() => { setOpenBasePrice(true); setPropertySelected(property) }}> <AttachMoneyIcon color="success"/> </IconButton>
    <IconButton onClick={() => { setOpenInteractiveMap(true); setPropertySelected(property) }}> <LayersIcon color="success"/> </IconButton>
  </>)

  if(isLoading || isFetching) return <div>Cargando...</div>
  if(error) return <div className={styles.box_container}> Ha ocurrido un error </div>

  return (<>
    <div className={ styles.toolbar_container }>
      <ToolBar searchFunction={handleSearch} />
    </div>
    <div className={styles.box_container} style={{ marginBottom: 55 }}>
      { dataFilter !== null &&
      dataFilter.map((property) => 
        <TypeOne key={property._id} actions={<Actions property={property}/>}
          name={property.property_name} 
          logo={property.logo !== "" ? property.logo : '/images/logo_gicsa_bw.png'} 
          image={property.property_photo !== "" ? property.property_photo : '/images/image_background_header.png'} />
      )}
    </div>
    <span className={styles.fab_add}>
      <Tooltip title="Agregar"><Fab color="primary" onClick={() => setOpenAdd(true)}><AddIcon /></Fab></Tooltip>
    </span>
    <Add open={openAdd} setOpen={setOpenAdd} reportAction={handleWatchAction}/> 
    <Edit open={openEdit} setOpen={setOpenEdit} property={propertySelected} reportAction={handleWatchAction}/>
    <ImagesLogos open={openImageLogos} setOpen={setOpenImageLogos} property={propertySelected} reportAction={handleWatchAction}/>
    <EditImagesData open={openEditImages} setOpen={setOpenEditImages} property={propertySelected} reportAction={handleWatchAction}/>
    <EditLocationData open={openEditLocation} setOpen={setOpenEditLocation} property={propertySelected} reportAction={handleWatchAction}/>
    <Amenities open={openAmenities} setOpen={setOpenAmenities} property={propertySelected} reportAction={handleWatchAction}/>
    <Facilities open={openFacilities} setOpen={setOpenFacilities} property={propertySelected} reportAction={handleWatchAction}/>
    <Mobilities open={openMobilities} setOpen={setOpenMobilities} property={propertySelected} reportAction={handleWatchAction}/>
    <Tenants open={openTenants} setOpen={setOpenTenants} property={propertySelected} reportAction={handleWatchAction}/>
    <Web open={openWeb} setOpen={setOpenWeb} property={propertySelected} reportAction={handleWatchAction}/>
    <BasePrice open={openBasePrice} setOpen={setOpenBasePrice} property={propertySelected} reportAction={handleWatchAction}/>
    <InteractiveMap open={openInteractiveMap} setOpen={setOpenInteractiveMap} floor={propertySelected} reportAction={handleWatchAction}/>
    <SnackbarGlobal vertical='bottom' horizontal='center' open={authError} close={() => setAuthError(false)} message={messageSnackbar} />
  </>)
}