import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  header_logo: Yup.string()
    .required("Campo requerido"),
  title: Yup.string()
    .required("Campo requerido"),
  slogan: Yup.string()
    .nullable(),
  phone: Yup.string()
    .required("Campo requerido"),
  email: Yup.string()
    .required("Campo requerido"),
  footer_logo: Yup.string()
    .required("Campo requerido"),
  footer_data: Yup.string()
    .required("Campo requerido"),
  footer_all_rights_reserved: Yup.string()
    .required("Campo requerido")
})


// Social Links validation
export const ValidationSchemaSocialLink = Yup.object().shape({
  link: Yup.string()
    .required("Campo requerido"),
  icon: Yup.string()
    .required("Campo requerido")
})

export const ValidationSchemaSocialLinks = Yup.object({
  social_links: Yup.array().of(
    Yup.object().shape({
      link: Yup.string().required("Campo requerido"),
      icon: Yup.string().required("Campo requerido")
    })
  )
})


// Codes validation
export const ValidationSchemaCodes = Yup.object().shape({
  head: Yup.string()
    .required("Campo requerido"),
  body: Yup.string()
    .required("Campo requerido")
})