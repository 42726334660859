//import firebase from 'firebase/app'
//import 'firebase/storage'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBmDoqMPJkRz8pkuk6EolQkySh2t3L9s1o",
  authDomain: "web-site-gicsa.firebaseapp.com",
  projectId: "web-site-gicsa",
  storageBucket: "web-site-gicsa.appspot.com",
  messagingSenderId: "47934196811",
  appId: "1:47934196811:web:edd67cda5782fa1494d1ce"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)

export const storage = getStorage(app)
