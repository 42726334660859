import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getPropertyMobilities = () =>
  fetch(
    `${API_BASE_URL}/property-mobilities`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN
    }
  )
  .then(res => res.json())


export const savePropertyMobility = async (params) => {
  const res = await fetch(
    `${API_BASE_URL}/property-mobilities`,
    {
      method: 'POST',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const editPropertyMobility = async (data) => {
  const { id, params } = data
  const res = await fetch(
    `${API_BASE_URL}/property-mobilities/${id}`,
    {
      method: 'PUT',
      headers: HEADERS_WITH_TOKEN,
      body: JSON.stringify(params)
    }
  )
  return await res.json()
}

export const deletePropertyMobility = async (id) => {
  const res = await fetch(
    `${API_BASE_URL}/property-mobilities/${id}`,
    {
      method: 'DELETE',
      headers: HEADERS_WITH_TOKEN
    }
  )
  return await res.json()
}