import { useEffect, useState } from 'react';
import $ from 'jquery'

const appendScript = (scriptUrl) => {
  const script = document.createElement("script")
  script.src = scriptUrl
  script.async = scriptUrl === "/js/mapplic/jquery.min.js" ? false : true
  document.body.appendChild(script)
}

const removeScript = (scriptToremove) => {
  let allsuspects = document.getElementsByTagName("script");
  for (let i = allsuspects.length; i >= 0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1 ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i])
    }    
  }
}

const appendStyle = (styleUrl) => {
  const style = document.createElement("link")
  style.href = styleUrl
  style.rel = "stylesheet"
  style.type = "text/css"
  document.head.appendChild(style)
}

const removeStyle = (scriptToremove) => {
  let allsuspects = document.getElementsByTagName("link");
  for (let i = allsuspects.length; i >= 0; i--){
    if (allsuspects[i] && allsuspects[i].getAttribute("href") !== null && allsuspects[i].getAttribute("href").indexOf(`${scriptToremove}`) !== -1 ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i])
    }    
  }
}

let dataJSON = {
	"mapwidth": "1000",
	"mapheight": "500",
	"defaultstyle": "light",
	"developer": true, 
	"styles": [
		{
			"class": "light",
			"hover": { "fill": "#f4f4f4" },
			"active": { "fill": "#fff" }
		},
		{
			"class": "dark",
			"hover": { "fill": "#666" },
			"active": { "fill": "#555" }
		}
	],
	"levels": [
		{
			"id": "piso",
			"title": "Piso",
			"map": "/images/ejemplo_9.svg",
			"locations": [
				{
					"id": "disponible",
					"title": "Disponible"
				},
				{
					"id": "ocupado",
					"title": "Ocupado"
				}
			]
		}
	]
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function TowerBuildingIM() {
  //const { towerBuilding } = locationC.state
  const [editorData, setEditorData] = useState(localStorage.getItem('inmaptb') ? JSON.parse(localStorage.getItem('inmaptb')) : dataJSON)

  const jQuerycode = () => {
    /*$(".button").click(function() {
      alert("Esto es alert con jQuery");
    });*/

    var map = $('#mapplic').mapplic({
      source: JSON.stringify(editorData),
      height: 500,
      mapfill: true,
      minimap: true,
      lightbox: true,
      fullscreen: true,
      thumbholder: true,
      landmark: 'entrance',
      developer: true,
      zoommargin: 0,
      maxscale: 1,

      bgcolor: '#333',
      bgcolor2: '#666',
      textcolor: '#aaa',
      headingcolor: '#fff'
    });
    self = map.data('mapplic');

    // EVENTS
    // Map ready
    map.on('mapready', function(e, self) {
      console.log('Map is ready!')
      // self grants direct access to the map object
      // The map will be focused on the washing machine by default
      //self.moveTo(0.67, 0.62, 3, 0);
    });

    // Location opened
    map.on('locationopened', function(e, location) {
      // location grants full access to the location
      console.log(location.title + ' opened.');
    });

    // Location closed
    map.on('locationclosed', function(e) {
      console.log('Location closed.');
    });

    // Level switched
    map.on('levelswitched', function(e, level) {
      console.log('Switched to ' + level + ' level.');
    });

    // Position changed
    map.on('positionchanged', function(e) {
      // self grants direct access to the map object
      console.log('Pan or zoom performed, current scale: ' + self.scale);
    });

    // METHODS
    // Getting mapplic object
    var self = map.data('mapplic');

    map.on('locationclosed', function(e) {
      //console.log(self);
    });
    
    $("#reload_map").on('click', function(e) {
      e.preventDefault();
      self.updateMapCustom(JSON.stringify(editorData))
    })
  }

  useEffect(() => {
    loadScripts()
    return () => {
      removeStyle("/css/mapplic/style.css")
      removeStyle("/css/mapplic/magnific-popup.css")
      removeStyle("/css/mapplic/mapplic.css")
      removeScript("/js/mapplic/jquery.min.js")
      removeScript("/js/mapplic/jquery.mousewheel.js")
      removeScript("/js/mapplic/script.js")
      //removeScript("/js/mapplic/magnific-popup.js")
      removeScript("/js/mapplic/csvparser.js")
      removeScript("/js/mapplic/mapplic.js")
      var elem = document.getElementById("code_mapplic")
      elem.remove()
    }
  }, [])

  const loadScripts = async () => {
    appendStyle("/css/mapplic/style.css")
    appendStyle("/css/mapplic/magnific-popup.css")
    appendStyle("/css/mapplic/mapplic.css")
    appendScript("/js/mapplic/jquery.min.js")
    await sleep(100)
    appendScript("/js/mapplic/jquery.mousewheel.js")
    await sleep(100)
    appendScript("/js/mapplic/script.js")
    await sleep(100)
    //appendScript("/js/mapplic/magnific-popup.js")
    //await sleep(100)
    appendScript("/js/mapplic/csvparser.js")
    await sleep(100)
    appendScript("/js/mapplic/mapplic.js")
    await sleep(100) 
    loadDataMapplic(editorData)
    //await sleep(100)
    //jQuerycode()
  }

  const loadDataMapplic = (data) => {
    const script = document.createElement("script")
    script.type = 'text/javascript';
    script.id = "code_mapplic"
    script.async = true 
    script.innerHTML = `
    $(document).ready(function() {
      var css = '.mapplic-filtered svg [id^=landmark] > * {opacity: 1 !important; }';
      var map = $('#mapplic').mapplic({
        source: ${JSON.stringify(data)},
        developer: true,
        customcss: css,
        sidebar: false,
        sidebartoggle: true,
        alphabetic: true,
        height: 500,
        developer: true,
        searchdescription: true,
        searcheverywhere: true,
        animations: true,
        minimap: false,
        marker: 'hidden',
        fillcolor: false,
        fullscreen: false,
        developer: false,
        thumbholder: true,
        maxscale: 1,
      });
      self = map.data('mapplic');

      // EVENTS
      // Map ready
      map.on('mapready', function(e, self) {
        console.log('Map is ready!')
        // self grants direct access to the map object
        // The map will be focused on the washing machine by default
        //self.moveTo(0.67, 0.62, 3, 0);
      });

      // Location opened
      map.on('locationopened', function(e, location) {
        // location grants full access to the location
        console.log(location.title + ' opened.');
      });

      // Location closed
      map.on('locationclosed', function(e) {
        console.log('Location closed.');
      });

      // Level switched
      map.on('levelswitched', function(e, level) {
        console.log('Switched to ' + level + ' level.');
      });

      // Position changed
      map.on('positionchanged', function(e) {
        // self grants direct access to the map object
        console.log('Pan or zoom performed, current scale: ' + self.scale);
      });

      // METHODS
      // Getting mapplic object
      var self = map.data('mapplic');

      map.on('locationclosed', function(e) {
        //console.log(self);
      });

      $(document).on('click', "#reload_map", function(e) {
        e.preventDefault();
        let data = $("#input_editor_data").val()
        $("#mapplic").empty()
        self.updateMapCustom(data)
      })
    });
    `
    document.body.appendChild(script)
  } 

  return (
    <div className="map-container">
    <div id="mapplic"></div>
    </div>
  );
}