import { useEffect, useState } from 'react'
import { CircularProgress, Fab, IconButton, Paper, Button, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Grid } from "@mui/material";
import FullwidthDialog from "../../../components/Dialog/FullwidthDialog";
import styles from '../styles.module.css'
import { useMutation } from 'react-query';
import { editSpaces } from '../../../services/api/Floor';
import { useQuery } from "react-query"
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaSpaces } from "../services/SchemaValidation";
import { getTenants } from '../../../services/api/Tenant'
import HorizontalData from '../../../components/Card/HorizontalDataCard';
import { Save as SaveIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon, Add as AddIcon, ImageSearch as ImageSearchIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import Add from './Add';
import Edit from './Edit';
import GenerateAutomaticGallery from '../../Media/GenerateAutomaticGallery'
import ImageCard from '../../../components/Card/ImageCard';

export default function Spaces({ open, setOpen, floor, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState(null)
  const [editPosition, setEditPosition] = useState(null)
  const [removePosition, setRemovePosition] = useState(null)
  const [instanceSpace, setInstanceSpace] = useState({})
  const [spaceSelected, setSpaceSelected] = useState({})
  const [spaceIndexSelected, setSpaceIndexSelected] = useState(null)
  const [openGenerateAG, setOpenGenerateAG] = useState(false)
  let schemaSpace = { rent_square_meters: "", useful_square_meters: "", status: "", tenant: null, conditioned: null, installations: null, furniture: null}
  /* spaces */
  let { /*handleSubmit: hTBP, formState: { errors: eTBP },*/ control: cTBP, setValue: svTBP } = useForm({ 
    resolver: yupResolver(ValidationSchemaSpaces), 
    defaultValues: { 'spaces': [] } 
  })
  const {fields: fTBP, append: aTBP, remove: rmvTBP, update: updTBP, move: mvTBP} = useFieldArray({ 
    name: 'spaces', control: cTBP 
  })
  /* tenants */
  const { isLoading: tLoading, isFetching: tFetching, refetch: tRefetch, error: tError, data: tenants } = useQuery(
    'tenantsList', getTenants, 
    { refetchOnWindowFocus: false, enabled: false }
  )

  useEffect(() => { 
    if(open) {  svTBP('spaces', floor.spaces_floor); tRefetch() } 
  }, [open])

  const mutation = useMutation(editSpaces, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { 
        reportAction('spaces', false, { message: 'Error al editar los datos'}, false)
      } else { 
        handleClose()
        reportAction('spaces', false, { }, true) 
      }
    }
  })

  const onSubmit = () => {
    setIsLoading(true)
    mutation.mutate({id: floor._id, params: fTBP})
  }

  const handleClose = () => { handleCancelEditSpace(); setOpen(false) }

  const handleCancelEditSpace = () => { setInstanceSpace({}); setMode(null) }

  const handleChangeUpPosition = (position) => { mvTBP(position, position - 1) }
  const handleChangeDownPosition = (position) => { mvTBP(position, position + 1) }

  const handleEditSpace = (position) => { 
    setEditPosition(position)
    setSpaceSelected(fTBP[position])
    setMode(false)
  }

  const handleShowTenant = (spaceTenantId) => {
    if(spaceTenantId !== undefined && spaceTenantId !== "" && spaceTenantId !== null) {
      console.log("handleShowTenant", spaceTenantId)
      let tenant = tenants.data.find(tenant => tenant._id === spaceTenantId)
      console.log("handleShowTenant", tenant)
      return { name: tenant.name, logo: tenant.logo }
    } else { return "" }
  }

  const handleRemoveSpace = (position) => { rmvTBP(position); setRemovePosition(null) }
  const handleCancelRemoveSpace = () => { setRemovePosition(null) }
  const handleShowRemoveSpace = (position) => { setRemovePosition(position) }


  const handleOpenMedia = (index) => {
    setSpaceIndexSelected(index)
    setOpenGenerateAG(true)
  }

  const handlerLoadImages = (images) => {
    let space = fTBP[spaceIndexSelected]
    space.images = []
    images.forEach(image => {
      let schemaTmp = { name: "", image: image, description: ""}
      space.images.push(schemaTmp)
    })
    updTBP(spaceIndexSelected, space)
  }

  const handleRemoveImage = (spaceIndex, imageIndex) => { 
    let space = fTBP[spaceIndex]
    space.images.splice(imageIndex, 1)
    updTBP(spaceIndex, space)
  }

  const handleChangeUpPositionImage = (spaceIndex, imageIndex) => {
    let space = fTBP[spaceIndex]
    let tmp = space.images[imageIndex]
    space.images[imageIndex] = space.images[imageIndex - 1]
    space.images[imageIndex - 1] = tmp
    updTBP(spaceIndex, space)
  }

  const handleChangeDownPositionImage = (spaceIndex, imageIndex) => {
    let space = fTBP[spaceIndex]
    let tmp = space.images[imageIndex]
    space.images[imageIndex] = space.images[imageIndex + 1]
    space.images[imageIndex + 1] = tmp
    updTBP(spaceIndex, space)
  }

  if(tLoading || tFetching) return <div>Cargando...</div>
  if(tError) return <div> Ha ocurrido un error </div>

  const Actions = ({ index, totalSpaces }) => (<>
    <IconButton onClick={() => handleEditSpace(index) }> <EditIcon color="info"/> </IconButton>
    <IconButton onClick={() => handleShowRemoveSpace(index)}> <DeleteIcon color="error"/> </IconButton>
    {index !== 0 && <IconButton aria-label="up-position" onClick={() => handleChangeUpPosition(index)}> <ArrowUpwardIcon /> </IconButton>}
    {index !== totalSpaces - 1 && <IconButton aria-label="down-position" onClick={() => handleChangeDownPosition(index)}> <ArrowDownwardIcon /> </IconButton> }
    <div style={{ marginTop: 16, borderTop: "1px solid #e1e1e1", paddingTop: 16, display: (removePosition === index) ? "block" : "none" }}>
      <div style={{ marginBottom: 16}}>¿Esta seguro de querer eliminar este espacio ?</div>
      <div>
        <Button onClick={() => handleCancelRemoveSpace()} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
        <Button onClick={() => handleRemoveSpace(index)} variant="contained" color="error"> Eliminar </Button>
      </div>
    </div>
  </>)

  return ( <>
    <FullwidthDialog open={open} changeOpen={handleClose} title={`Actualizar espacios disponibles - ${floor.name}`} actions={<>
      { !isLoading ? 
      <IconButton autoFocus color="inherit" onClick={() => onSubmit()} aria-label="save"> <SaveIcon /> </IconButton> 
      : <CircularProgress /> }
    </>}>
      <div className={styles.container_spaces_main}>
        <div className={styles.container_spaces_cards}>
          {fTBP.map((spaces, index) => {
            let tenant = handleShowTenant(spaces.tenant);
            return ( <>
            <HorizontalData key={index} actionsAlign="center" actions={<Actions index={index} totalSpaces={fTBP.length} />}>
              { (tenant !== "") && <div className={styles.card_image}> <img src={tenant.logo} style={{ height: 120 }}/> </div> }
              <div className={styles.card_status} style={{ backgroundColor: spaces.status === 1 ? "#7D868D" : spaces.status === 2 ? "#F6D901" : "#D63441"}}>
                {spaces.status === 1 ? "Disponible" : spaces.status === 2 ? "Apartado" : "Rentado"}
              </div>
              <div className={styles.card_main_meters}> 
                <div className={styles.card_item_meters}>
                  <span className={styles.card_meters}> {spaces.rent_square_meters} m2 </span>
                  <span className={styles.card_meters_text}> Metros cuadrados en renta </span>
                </div>
                <div className={styles.card_item_meters}>
                  <span className={styles.card_meters}> {spaces.useful_square_meters} m2 </span>
                  <span className={styles.card_meters_text}> Metros cuadrados usables </span>
                </div>
              </div>
              <div className={styles.card_main_meters}> 
                <div className={styles.card_item_meters}>
                  <span className={styles.card_meters}> {spaces.conditioned === 2 ? "Sí" : "No"} </span>
                  <span className={styles.card_meters_text}> Acondicionado </span>
                </div>
                <div className={styles.card_item_meters}>
                  <span className={styles.card_meters}> {spaces.installations === 2 ? "Sí" : "No"} </span>
                  <span className={styles.card_meters_text}> Instalaciones </span>
                </div>
                <div className={styles.card_item_meters}>
                  <span className={styles.card_meters}> {spaces.furniture === 2 ? "Sí" : "No"} </span>
                  <span className={styles.card_meters_text}> Moviliario </span>
                </div>
              </div>
              <div>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Galería de Imagenes</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <IconButton onClick={() => handleOpenMedia(index)}> <ImageSearchIcon color="info" /> </IconButton><br />
                    <Grid container spacing={2}>
                    { spaces.images !== undefined && spaces.images.map((image, imageIndex) => {
                      return ( <Grid item xs={12}>
                        <ImageCard key={imageIndex} index={imageIndex} totalItems={spaces.images.length}  image={image.image} name={image.name} description={image.description} remove={() => handleRemoveImage(index, imageIndex)} changeUpPosition={() => handleChangeUpPositionImage(index, imageIndex)} changeDownPosition={() => handleChangeDownPositionImage(index, imageIndex)} />
                      </Grid> )
                    }) }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </HorizontalData>
            </> )
          })}
        </div>
        <Paper style={{padding: 20, borderRadius: 16}}>
        { (mode === null) &&  <div style={{ textAlign: "right" }}>
          <Fab color="primary" aria-label="add" onClick={() => setMode(true)}> <AddIcon /> </Fab>
        </div> }
        { (mode === true) &&
          <Add isLoading={isLoading} tenants={tenants}  instanceSpace={instanceSpace}  setInstanceSpace={setInstanceSpace}  schemaSpace={schemaSpace}  setMode={setMode} addSpace={aTBP} /> }
        { (mode === false) && 
          <Edit isLoading={isLoading} tenants={tenants}  instanceSpace={instanceSpace}  setInstanceSpace={setInstanceSpace}  schemaSpace={schemaSpace}  mode={mode}  setMode={setMode} spaceSelected={spaceSelected} position={editPosition}  editSpace={updTBP} /> }
        </Paper>
      </div>
    </FullwidthDialog>
    <GenerateAutomaticGallery open={openGenerateAG} setOpen={setOpenGenerateAG} setImages={ handlerLoadImages } path={`pisos/${floor._id}/photos`} />
  </> )
}