import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaAmenity } from "../services/SchemaValidation"
import { Button, IconButton, InputLabel, TextField, Typography  } from "@mui/material";
import { ImageSearch as ImageSearchIcon } from '@mui/icons-material'
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useForm } from "react-hook-form";

export default function AddEditForm({ isLoading, editPosition, mode, setMode, handleOpenMedia, updateArray, appendArray, setValues }) {
  let schemaAmenity = { icon: "", description: "" }
  const [instanceAmenity, setInstanceAmenity] = useState(schemaAmenity)
  //Amenity
  let { reset: resetOne, handleSubmit: handleSubmitOne, register: registerOne, formState: { errors: errorsOne }, setValue: setValueOne} = useForm({ resolver: yupResolver(ValidationSchemaAmenity) })

  useEffect(() => {
    if(setValues !==  null) {
      let instanceTemp = {...instanceAmenity}
      setValues.forEach(data => {
        if(data.reference === "reset") {
          resetOne(schemaAmenity);
        } else {
          setValueOne(data.reference, data.value)
          instanceTemp[data.reference] = data.value
        }
      })
      setInstanceAmenity(instanceTemp)
    }
  }, [setValues]) 
  
  const handleChangeData = (event) => { 
    setInstanceAmenity({...instanceAmenity, [event.target.name]: event.target.value}) 
  }

  const handleCancelEditAmenity = () => { setInstanceAmenity({}); resetOne(schemaAmenity); setMode(true) }

  const handleSaveEdit = () => {
    updateArray(editPosition, instanceAmenity)
    setInstanceAmenity({}); resetOne(schemaAmenity); setMode(true)
  }

  const onAddAmenity = (params) => {
    appendArray(params)
    resetOne(schemaAmenity)
    setInstanceAmenity(schemaAmenity)
  }

  return (<>
    <Typography variant="inherit">{ mode ? "Agregar" : "Editar" } extra logo</Typography><br />
    { (instanceAmenity.icon !== undefined && instanceAmenity.icon !== null && instanceAmenity.icon !== "") && <div style={{ textAlign: "center" }}>
      <img alt="preview-url" src={instanceAmenity.icon} style={{ width: "100%", maxWidth: "250px" }}/>
    </div> }
    <InputLabel htmlFor='icon' required style={{ fontSize: 14 }}>Ícono</InputLabel>
    <TextField id='icon' fullWidth margin="dense" type="text" name='icon'
      value={instanceAmenity.icon || ""}
      {...registerOne(`icon`)} 
      error={errorsOne.icon ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.icon?.message} />
    <IconButton onClick={() => handleOpenMedia("addlogo")}> <ImageSearchIcon color="info" /> </IconButton><br />
    <InputLabel htmlFor='description' required style={{ fontSize: 14 }}>Descripción</InputLabel>
    <TextField id='description' fullWidth margin="dense" type="text" name='description'
      value={instanceAmenity.description || ""}
      {...registerOne(`description`)} 
      error={errorsOne.description ? true: false}
      onChange={handleChangeData} />
    <ValidateErrorMessage message={errorsOne.description?.message} />
    {!isLoading ? <div style={{ textAlign: "right" }}>
      { (!mode) ? <>
        <Button onClick={handleCancelEditAmenity} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
        <Button onClick={handleSaveEdit} variant="contained" color="secondary"> Actualizar </Button>
      </> :  <Button onClick={handleSubmitOne(onAddAmenity)} variant="contained" color="primary"> Agregar </Button> }
    </div> : <></> }      
  </>)
}