import { useEffect, useState } from "react";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import DialogC from "../../../components/Dialog";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useMutation } from 'react-query'
import { editTowerBuilding } from "../../../services/api/TowerBuilding";

export default function Edit({ open, setOpen, towerBuilding, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataTowerBuilding, setDataTowerBuilding] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  useEffect(() => {
    if(open) {
      setDataTowerBuilding(towerBuilding)
      setValue("property", towerBuilding.property)
      setValue("name", towerBuilding.name)
      setValue("rent_meters", towerBuilding.rent_meters)
      setValue("architectural_plan", towerBuilding.architectural_plan)
      setValue("price_square_meter", towerBuilding.price_square_meter)
    }
  }, [open])

  const mutation = useMutation(editTowerBuilding, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('edit', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('edit', false, { }, true) }
    }
  })

  const handleEdit = (params) => { 
    console.log("prueba", params)
    console.log("id", dataTowerBuilding._id)
    setIsLoading(true)
    mutation.mutate({id: dataTowerBuilding._id, params: params})
  }

  const handleClose = () => { reset(); setDataTowerBuilding({}); setOpen(false) }

  const handleOnChangeData = (event) => { 
    setDataTowerBuilding({...dataTowerBuilding, [event.target.name]: event.target.value}) 
  }

  const handleChangeActive = (event) => {
    setDataTowerBuilding({...dataTowerBuilding, active: !dataTowerBuilding.active}) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (
    <DialogC open={open} title={`Editar`} actions={<Actions />}>
      <>
      <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataTowerBuilding.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <InputLabel htmlFor='rent_meters' style={{ fontSize: 14 }} required>Metros cuadrados rentables</InputLabel>
        <TextField id='rent_meters' fullWidth margin="dense" type="number" name='rent_meters'
          value={dataTowerBuilding.rent_meters || ""}
          {...register(`rent_meters`)} 
          error={errors.rent_meters ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.rent_meters?.message} />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataTowerBuilding.architectural_plan || "/images/picture_empty.png"} style={{ width: "50%" }}/>
          </div>
          <div>
          <InputLabel htmlFor='architectural_plan' style={{ fontSize: 14 }} required>Plano arquitectónico</InputLabel>
          <TextField id='architectural_plan' fullWidth margin="dense" type="text" name='architectural_plan'
            value={dataTowerBuilding.architectural_plan || ""}
            {...register(`architectural_plan`)} 
            error={errors.architectural_plan ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.architectural_plan?.message} />
          </div>
        </div>
        <br />
        <InputLabel htmlFor='price_square_meter' style={{ fontSize: 14 }} required>Precio por metro cuadrado</InputLabel>
        <TextField id='price_square_meter' fullWidth margin="dense" type="number" name='price_square_meter'
          value={dataTowerBuilding.price_square_meter || ""}
          {...register(`price_square_meter`)} 
          error={errors.price_square_meter ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.price_square_meter?.message} />
      </>
    </DialogC>
  )
}