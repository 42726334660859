import { API_BASE_URL, HEADERS_WITH_TOKEN } from "./BaseUrl";

export const getStates = () =>
  fetch(
    `${API_BASE_URL}/states`,
    {
      method: 'GET',
      headers: HEADERS_WITH_TOKEN
    }
  )
  .then(res => res.json())