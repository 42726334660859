export const API_BASE_URL = "https://gicsa-api-web-x7gq7zmvhq-uc.a.run.app/api/v1";
//export const API_BASE_URL = "http://localhost:8000/api/v1";

export const HEADERS_WITH_TOKEN = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('mstn')}`
}

export const HEADERS_WITH_TOKEN_WITHOUT_CONTENTTYPE = {
    'Authorization': `Bearer ${localStorage.getItem('mstn')}`
}