import { useEffect, useState } from "react";
import { Button, CircularProgress, IconButton, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, Select, TextField } from "@mui/material";
import FullwidthDialog from "../../../../components/Dialog/FullwidthDialog";
import { useMutation } from "react-query";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaLocation } from "../../services/SchemaValidation";
import { useQuery, useQueryClient } from "react-query";
import { getStates } from '../../../../services/api/State'
import { getCitiesByState } from '../../../../services/api/City'
import { editPropertyLocation } from '../../../../services/api/Property'
import TextFieldGroup from "../../../../components/InputsPresentation/TextFieldGroup";
import { ValidateErrorMessage } from "../../../../components/ValidateErrorMessage";
import SaveIcon from '@mui/icons-material/Save';
import styles from './styles.module.css'

export default function EditLocationData({ open, setOpen, property, reportAction }) {
  const { _id, location } = property
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [dataLocationData, setDataLocationData] = useState({georeference: {}})
  let { reset, handleSubmit, register, formState: { errors }, control, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchemaLocation),
  })
  const { fields, append, remove } = useFieldArray({ name: 'landing_page', control })
  const queryClient = useQueryClient()
  const { isLoading: sLoading, isFetching: sFetching, refetch: sRefetch, error: sError, data: states } = useQuery(
    'statesList', 
    getStates, 
    { refetchOnWindowFocus: false/*, enabled: false*/ }
  )
  const { isLoading: cbsLoading, isFetching: cbsFetching, error: cbsError, data: citiesByState } = useQuery(
    ['citiesByStateList', state], 
    () => getCitiesByState(state), 
    { refetchOnWindowFocus: false,  enabled: (state !== null && state !== undefined && state !== "") }
  )
  const schemaLP = { language: "", url: "" }  

  useEffect(() => { 
    if(open) { 
      setDataLocationData(location !== undefined ? location : {georeference: {}})
      const keys = ["address", "email", "phone", "facebook", "whatsapp", "waze", "georeference", "google_maps"]
      for (let index = 0; index < keys.length; index++) { setValue(keys[index], property[keys[index]]) }
      setState(location !== undefined ? location.state : "")
      setCity(location !== undefined ? location.city : "")
      setValue('landing_page', location !== undefined ? location.landing_page : [])
    } 
  }, [open])

  const handleClose = () => { reset(); setDataLocationData({georeference: {}}); setOpen(false) }

  const mutation = useMutation(editPropertyLocation, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('editLocation', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('editLocation', false, { }, true) }
    }
  })

  const onSubmit = (params) => {
    console.log("Params:", params)
    setIsLoading(true)
    mutation.mutate({id: _id, params: params})
  }

  const handleAddLP = () => { append(schemaLP) }
  const handleRemoveLP = (position) => { remove(position) }
  const handleChangeState = (id) => { setState(id); setCity(""); queryClient.invalidateQueries('citiesByStateList') }
  const handleChangeCity = (event) => { setCity(event.target.value) }
  const handleOnChangeData = (event) => { 
    setDataLocationData({...dataLocationData, [event.target.name]: event.target.value}) 
  }
  const handleOnChangeDataGeoLatLong = (event) => { 
    let geoTemp = { 
      latitude: dataLocationData.georeference.latitude,
      longitude: dataLocationData.georeference.longitude
    }
    if(event.target.name === "georeference.latitude") {
      geoTemp.latitude = event.target.value
    } else { geoTemp.longitude = event.target.value }
    setDataLocationData({...dataLocationData, georeference: geoTemp}) 
  }

  return (
    <FullwidthDialog open={open} changeOpen={handleClose} title="Editar localización" actions={<>
      { !isLoading ? <>
      <IconButton autoFocus color="inherit" onClick={handleSubmit(onSubmit)} aria-label="save"> <SaveIcon /> </IconButton> </> : <CircularProgress /> }
      </>}>
      {sError || cbsError ? <div>Ha ocurrido un error</div> :
      (sLoading || sFetching) ? <div>Cargando...</div> :
      <div className={styles.box_container}>
        <Paper style={{padding: 24, borderRadius: 16}}>
          <Typography variant="inherit">Dirección</Typography><br/>
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-state-label">Estado</InputLabel>
            <Select labelId="select-state-label" value={state} label="Estado" required {...register(`state`)} error={errors.state ? true : false} inputProps={{ name: 'state', id: 'select-state' }}>
              <MenuItem value="" onClick={() => handleChangeState("")}>Seleccionar</MenuItem>
              { states.data.map((state, index) => (
                <MenuItem key={index} value={state._id} onClick={() => handleChangeState(state._id)}>{state.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <ValidateErrorMessage message={errors.state?.message} />
          { (cbsLoading || cbsFetching) ? <div><CircularProgress /></div> :
          (citiesByState !== undefined) && <>
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-city">Ciudad</InputLabel>
            <Select id="select-city" defaultValue={city || ''} label="Ciudad" onChange={handleChangeCity}
              name="" required {...register(`city`)} error={errors.city ? true : false} >
              <MenuItem value="">Seleccionar</MenuItem>
              { citiesByState.data.map((city, index) => (
                <MenuItem key={index} value={city._id}>{city.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <ValidateErrorMessage message={errors.city?.message} /> </>}

          <InputLabel htmlFor='address' required style={{ fontSize: 14 }}>Dirección</InputLabel>
          <TextField id='address' fullWidth margin="dense" type="text" name='address'
            value={dataLocationData.address || ""}
            {...register(`address`)} 
            error={errors.address ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.address?.message} />

        </Paper>
        <Paper style={{padding: 24, borderRadius: 16}}>
          <Typography variant="inherit">Datos de contacto</Typography><br/>
          
          <InputLabel htmlFor='email' required style={{ fontSize: 14 }}>Correo electrónico</InputLabel>
          <TextField id='email' fullWidth margin="dense" type="text" name='email'
            value={dataLocationData.email || ""}
            {...register(`email`)} 
            error={errors.email ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.email?.message} />

          <InputLabel htmlFor='phone' required style={{ fontSize: 14 }}>Teléfono</InputLabel>
          <TextField id='phone' fullWidth margin="dense" name='phone' type="number"
            value={dataLocationData.phone || ""}
            {...register(`phone`)} 
            error={errors.phone ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.phone?.message} />
          
          <InputLabel htmlFor='facebook' required style={{ fontSize: 14 }}>Facebook</InputLabel>
          <TextField id='facebook' fullWidth margin="dense" type="text" name='facebook'
            value={dataLocationData.facebook || ""}
            {...register(`facebook`)} 
            error={errors.facebook ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.facebook?.message} />

          <InputLabel htmlFor='whatsapp' required style={{ fontSize: 14 }}>Whatsapp</InputLabel>
          <TextField id='whatsapp' fullWidth margin="dense" type="text" name='whatsapp'
            value={dataLocationData.whatsapp || ""}
            {...register(`whatsapp`)} 
            error={errors.whatsapp ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.whatsapp?.message} />
          
          <InputLabel htmlFor='waze' required style={{ fontSize: 14 }}>Waze</InputLabel>
          <TextField id='waze' fullWidth margin="dense" type="text" name='waze'
            value={dataLocationData.waze || ""}
            {...register(`waze`)} 
            error={errors.waze ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.waze?.message} />
        </Paper>
        <Paper style={{padding: 24, borderRadius: 16}}>
          <Typography variant="inherit">Geolocalización</Typography><br/>
          <InputLabel htmlFor='latitude' required style={{ fontSize: 14 }}>Latitud</InputLabel>
          <TextField id='latitude' fullWidth margin="dense" name='latitude' type="number"
            value={dataLocationData.georeference.latitude || ""}
            {...register(`georeference.latitude`)} 
            error={errors.georeference?.latitude ? true: false}
            onChange={handleOnChangeDataGeoLatLong} />
          <ValidateErrorMessage message={errors.georeference?.latitude?.message} />

          <InputLabel htmlFor='longitude' required style={{ fontSize: 14 }}>Longitud</InputLabel>
          <TextField id='latitude' fullWidth margin="dense" name='longitude' type="number"
            value={dataLocationData.georeference.longitude || ""}
            {...register(`georeference.longitude`)} 
            error={errors.georeference?.longitude ? true: false}
            onChange={handleOnChangeDataGeoLatLong} />
          <ValidateErrorMessage message={errors.georeference?.longitude?.message} />

          <InputLabel htmlFor='google_maps' required style={{ fontSize: 14 }}>Google Maps</InputLabel>
          <TextField id='google_maps' fullWidth margin="dense" type="text" name='google_maps'
            value={dataLocationData.google_maps || ""}
            {...register(`google_maps`)} 
            error={errors.google_maps ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.google_maps?.message} />
        </Paper>
        <Paper style={{padding: 24, borderRadius: 16}}>
          <Typography variant="inherit">Landing pages</Typography><br/>
          <div>
            {fields.map((lp, i) => (<div key={i}>
              <TextFieldGroup label="Lenguaje" inputName={`landing_page[${i}]language`} required={true} 
                defaultValue={""} 
                rhfRegister={register(`landing_page.${i}.language`)}
                inputError={errors.landing_page?.[i]?.language}
                errorMessage={errors.landing_page?.[i]?.language?.message} />
              <TextFieldGroup label="Url" inputName={`landing_page[${i}]url`} required={true} 
                defaultValue={""} 
                rhfRegister={register(`landing_page.${i}.url`)}
                inputError={errors.landing_page?.[i]?.url}
                errorMessage={errors.landing_page?.[i]?.url?.message} />
              <div style={{ textAlign: "right" }}> <Button variant="contained" color="error" onClick={() => handleRemoveLP(i)}> Eliminar </Button> </div>
              <hr />
            </div>))}
          </div>
          <div style={{ textAlign: "right" }}> <Button variant="contained" color="secondary" onClick={() => handleAddLP()}> Agregar </Button> </div>
        </Paper>
      </div>
      }
    </FullwidthDialog>
  )
}