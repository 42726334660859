import { useEffect, useState } from "react";
import { Button, CircularProgress, InputLabel, MenuItem, TextField } from "@mui/material";
import DialogC from "../../../components/Dialog";
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "../services/SchemaValidation"
import { ValidateErrorMessage } from "../../../components/ValidateErrorMessage";
import { useMutation } from 'react-query'
import { editFloor } from "../../../services/api/Floor";

export default function Edit({ open, setOpen, floor, reportAction }) {
  const [isLoading, setIsLoading] = useState(false)
  const [dataFloor, setDataFloor] = useState({})
  let { reset, handleSubmit, register, formState: { errors }, setValue }  = useForm({ 
    resolver: yupResolver(ValidationSchema)
  })

  useEffect(() => {
    if(open) {
      setDataFloor(floor)
      setValue("tower_building", floor.tower_building)
      setValue("name", floor.name)
      setValue("architectural_plan", floor.architectural_plan)
      setValue("rent_square_meters", floor.rent_square_meters)
      setValue("useful_square_meters", floor.useful_square_meters)
      setValue("rent_square_meters_available", floor.rent_square_meters_available)
      setValue("useful_square_meters_available", floor.useful_square_meters_available)
      setValue("conditioned", floor.conditioned)
      setValue("installations", floor.installations)
      setValue("furniture", floor.furniture)
    }
  }, [open])

  const mutation = useMutation(editFloor, {
    onSuccess: (data) => {
      setIsLoading(false)
      if(data.status === "error") { reportAction('edit', false, { message: 'Error al editar los datos'}, false)
      } else { reportAction('edit', false, { }, true) }
    }
  })

  const handleEdit = (params) => { 
    console.log("prueba", params)
    console.log("id", dataFloor._id)
    setIsLoading(true)
    mutation.mutate({id: dataFloor._id, params: params})
  }

  const handleClose = () => { reset(); setDataFloor({}); setOpen(false) }

  const handleOnChangeData = (event) => { 
    setDataFloor({...dataFloor, [event.target.name]: event.target.value}) 
  }

  const handleChangeActive = (event) => {
    setDataFloor({...dataFloor, active: !dataFloor.active}) 
  }

  const Actions = () => (<>
    { !isLoading ? <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button variant="contained" color="info" onClick={handleSubmit(handleEdit)}>Editar</Button>
      </> : <CircularProgress /> }
  </>)

  return (
    <DialogC open={open} title={`Editar`} actions={<Actions />}>
      <>
      <InputLabel htmlFor='name' required style={{ fontSize: 14 }}>Nombre</InputLabel>
        <TextField id='name' fullWidth margin="dense" type="text" name='name'
          value={dataFloor.name || ""}
          {...register(`name`)} 
          error={errors.name ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.name?.message} />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%"}}>
          <div style={{ textAlign: "center" }}>
            <img src={dataFloor.architectural_plan || "/images/picture_empty.png"} style={{ width: "50%" }}/>
          </div>
          <div>
          <InputLabel htmlFor='architectural_plan' style={{ fontSize: 14 }} required>Plano arquitectónico</InputLabel>
          <TextField id='architectural_plan' fullWidth margin="dense" type="text" name='architectural_plan'
            value={dataFloor.architectural_plan || ""}
            {...register(`architectural_plan`)} 
            error={errors.architectural_plan ? true: false}
            onChange={handleOnChangeData} />
          <ValidateErrorMessage message={errors.architectural_plan?.message} />
          </div>
        </div>
        <br />

        <InputLabel htmlFor='rent_square_meters' style={{ fontSize: 14 }} required>Metros cuadrados rentables</InputLabel>
        <TextField id='rent_square_meters' fullWidth margin="dense" type="number" name='rent_square_meters'
          value={dataFloor.rent_square_meters || ""}
          {...register(`rent_square_meters`)} 
          error={errors.rent_square_meters ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.rent_square_meters?.message} />
        <br />
        <InputLabel htmlFor='useful_square_meters' style={{ fontSize: 14 }} required>Metros cuadrados usables</InputLabel>
        <TextField id='useful_square_meters' fullWidth margin="dense" type="number" name='useful_square_meters'
          value={dataFloor.useful_square_meters || ""}
          {...register(`useful_square_meters`)} 
          error={errors.useful_square_meters ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.useful_square_meters?.message} />
        <br />
        <InputLabel htmlFor='rent_square_meters_available' style={{ fontSize: 14 }} required>Metros cuadrados rentables disponibles</InputLabel>
        <TextField id='rent_square_meters_available' fullWidth margin="dense" type="number" name='rent_square_meters_available'
          value={dataFloor.rent_square_meters_available || ""}
          {...register(`rent_square_meters_available`)} 
          error={errors.rent_square_meters_available ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.rent_square_meters_available?.message} />
        <br />
        <InputLabel htmlFor='useful_square_meters_available' style={{ fontSize: 14 }} required>Metros cuadrados usables disponibles</InputLabel>
        <TextField id='useful_square_meters_available' fullWidth margin="dense" type="number" name='useful_square_meters_available'
          value={dataFloor.useful_square_meters_available || ""}
          {...register(`useful_square_meters_available`)} 
          error={errors.useful_square_meters_available ? true: false}
          onChange={handleOnChangeData} />
        <ValidateErrorMessage message={errors.useful_square_meters_available?.message} />
        <br />
        <InputLabel htmlFor="space-conditioned" style={{ fontSize: 14 }} required>Acondicionada</InputLabel>
        <TextField id="space-conditioned" fullWidth margin="dense" select name="space-conditioned"
          value={dataFloor.conditioned || ""}
          {...register(`conditioned`)} 
          error={errors.conditioned ? true: false}
          onChange={handleOnChangeData}>
            <MenuItem value={1}> No </MenuItem>
            <MenuItem value={2}> Sí </MenuItem>
        </TextField>
        <ValidateErrorMessage message={errors.conditioned?.message} /> <br />
        <InputLabel htmlFor="space-installations" style={{ fontSize: 14 }} required>Instalaciones</InputLabel>
        <TextField id="space-installations" fullWidth margin="dense" select name="space-installations"
          value={dataFloor.installations || ""}
          {...register(`installations`)} 
          error={errors.installations ? true: false}
          onChange={handleOnChangeData}>
            <MenuItem value={1}> No </MenuItem>
            <MenuItem value={2}> Sí </MenuItem>
        </TextField>
        <ValidateErrorMessage message={errors.installations?.message} /> <br />
        <InputLabel htmlFor="space-furniture" style={{ fontSize: 14 }} required>Mobiliario</InputLabel>
        <TextField id="space-furniture" fullWidth margin="dense" select name="space-furniture"
          value={dataFloor.furniture || ""}
          {...register(`furniture`)} 
          error={errors.furniture ? true: false}
          onChange={handleOnChangeData}>
            <MenuItem value={1}> No </MenuItem>
            <MenuItem value={2}> Sí </MenuItem>
        </TextField>
        <ValidateErrorMessage message={errors.furniture?.message} /> <br />
      </>
    </DialogC>
  )
}