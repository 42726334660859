import * as Yup from 'yup'

export const ValidationSchema = Yup.object().shape({
  property: Yup.string()
    .required("Campo requerido"),
  name: Yup.string()
    .required("Campo requerido"),
  rent_meters: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido"),
  architectural_plan: Yup.string()
    .required("Campo requerido"),
  price_square_meter: Yup.number()
    .typeError("Campo debe ser numérico")
    .required("Campo requerido")
})