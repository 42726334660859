import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Button, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { ValidateErrorMessage } from '../../../components/ValidateErrorMessage';
import { StatusSpace } from '../services/FloorConsts';
import { ValidationSchemaSpace } from "../services/SchemaValidation";

export default function Add({ isLoading, tenants, instanceSpace, setInstanceSpace, schemaSpace, setMode, addSpace }) {
	let { reset, handleSubmit, register, formState: { errors }, setValue } = useForm({ resolver: yupResolver(ValidationSchemaSpace) })

  const onChangeTenant = (event, newValue) => { 
    setValue('tenant', newValue._id); 
    setInstanceSpace({...instanceSpace, tenant: newValue._id})
    console.log("onChangeTenant", newValue) 
  }

  const handleOnChangeData = (event) => { 
		setInstanceSpace({...instanceSpace, [event.target.name]: event.target.value})
	}

	const handleAddSpace = (params) => {
    console.log(params)
    addSpace(params)
    reset(schemaSpace)
    setInstanceSpace(schemaSpace)
		setMode(null)
  }

  const handleCancelAddSpace = () => { setInstanceSpace({}); reset(schemaSpace); setMode(null) }

  return (<>
    <Typography variant="inherit">Agregar espacio</Typography><br />
    <InputLabel htmlFor='rent_square_meters' style={{ fontSize: 14 }} required>Metros cuadrados rentables</InputLabel>
    <TextField id='rent_square_meters' fullWidth margin="dense" type="number" name='rent_square_meters'
      value={instanceSpace.rent_square_meters || ""}
      {...register(`rent_square_meters`)} 
      error={errors.rent_square_meters ? true: false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.rent_square_meters?.message} /> <br />
    <InputLabel htmlFor='useful_square_meters' style={{ fontSize: 14 }} required>Metros cuadrados usables</InputLabel>
    <TextField id='useful_square_meters' fullWidth margin="dense" type="number" name='useful_square_meters'
      value={instanceSpace.useful_square_meters || ""}
      {...register(`useful_square_meters`)} 
      error={errors.useful_square_meters ? true: false}
      onChange={handleOnChangeData} />
    <ValidateErrorMessage message={errors.useful_square_meters?.message} /> <br />
    <InputLabel htmlFor="space-status" style={{ fontSize: 14 }} required>Estatus</InputLabel>
    <TextField id="space-status" fullWidth margin="dense" select name="space-status"
      value={instanceSpace.status || ""}
      {...register(`status`)} 
      error={errors.status ? true: false}
      onChange={handleOnChangeData}>
      { StatusSpace.map((status) => 
        <MenuItem key={status.id} value={status.id}> {status.text} </MenuItem>
      )}
    </TextField>
    <ValidateErrorMessage message={errors.status?.message} /> <br />
    {(tenants !== undefined) && <>
    <InputLabel htmlFor="space-tenant" style={{ fontSize: 14 }}>Inquilino</InputLabel>
    <Autocomplete
      id="combo-box-tenants"
      options={tenants.data}
      getOptionLabel={(option) => option.name}
      onChange={onChangeTenant}
      renderInput={ (params) => <TextField {...params} error={errors.tenant ? true: false} />}
    />
    <ValidateErrorMessage message={errors.tenant?.message} /> <br />
    </>}
    <InputLabel htmlFor="space-conditioned" style={{ fontSize: 14 }} required>Acondicionada</InputLabel>
    <TextField id="space-conditioned" fullWidth margin="dense" select name="space-conditioned"
      value={instanceSpace.conditioned || 1}
      {...register(`conditioned`)} 
      error={errors.conditioned ? true: false}
      onChange={handleOnChangeData}>
        <MenuItem value={1}> No </MenuItem>
        <MenuItem value={2}> Sí </MenuItem>
    </TextField>
    <ValidateErrorMessage message={errors.conditioned?.message} /> <br />
    <InputLabel htmlFor="space-installations" style={{ fontSize: 14 }} required>Instalaciones</InputLabel>
    <TextField id="space-installations" fullWidth margin="dense" select name="space-installations"
      value={instanceSpace.installations || 1}
      {...register(`installations`)} 
      error={errors.installations ? true: false}
      onChange={handleOnChangeData}>
        <MenuItem value={1}> No </MenuItem>
        <MenuItem value={2}> Sí </MenuItem>
    </TextField>
    <ValidateErrorMessage message={errors.installations?.message} /> <br />
    <InputLabel htmlFor="space-furniture" style={{ fontSize: 14 }} required>Mobiliario</InputLabel>
    <TextField id="space-furniture" fullWidth margin="dense" select name="space-furniture"
      value={instanceSpace.furniture || 1}
      {...register(`furniture`)} 
      error={errors.furniture ? true: false}
      onChange={handleOnChangeData}>
        <MenuItem value={1}> No </MenuItem>
        <MenuItem value={2}> Sí </MenuItem>
    </TextField>
    <ValidateErrorMessage message={errors.furniture?.message} /> <br />
    {!isLoading ? <div style={{ textAlign: "right" }}>      
			<Button onClick={handleCancelAddSpace} variant="outlined" color="primary"> Cancelar </Button> &nbsp;
      <Button onClick={handleSubmit(handleAddSpace)} variant="contained" color="primary"> Agregar </Button>
    </div> : <></> }
  </> )
}